import { Business, ChatBubble, EventNote, People } from "@material-ui/icons";
import { CommentEdit, CommentList } from "../comments";
import { NewsCreate, NewsEdit, NewsList, NewsShow } from "../news";
import {
  StructureCreate,
  StructureEdit,
  StructureList,
  StructureShow,
} from "../structures";
import { UserEdit, UserList, UserShow } from "../users";

import { ParticipationList } from "../participations";
import { ParticipationShow } from "../protocols/vigieterre/participations";
import React from "react";
import { Resource } from "react-admin";

const observatoryId = process.env.REACT_APP_VIGIETERRE_OBS_ID;

const roles = [
  { id: "user", name: "Membre" },
  { id: "expert", name: "Expert" },
  { id: "animnat", name: "Animateur national" },
];

export const newsTypes = [
  { id: "article", name: "Article" },
  { id: "publication-scientifique", name: "Publication scientifique" },
  { id: "evenement", name: "Évenement" },
];

const ObsUserList = (props) => (
  <UserList observatoryId={observatoryId} roles={roles} {...props} />
);

const ObsUserEdit = (props) => (
  <UserEdit observatoryId={observatoryId} roles={roles} {...props} />
);

const ObsNewsList = (props) => (
  <NewsList observatoryId={observatoryId} newsTypes={newsTypes} {...props} />
);

const ObsNewsEdit = (props) => <NewsEdit newsTypes={newsTypes} {...props} />;

const ObsNewsShow = (props) => <NewsShow newsTypes={newsTypes} {...props} />;

const ObsNewsCreate = (props) => (
  <NewsCreate newsTypes={newsTypes} {...props} />
);

const ObsParticipationList = (props) => (
  <ParticipationList observatoryId={observatoryId} {...props} />
);

const ObsCommentList = (props) => (
  <CommentList observatoryId={observatoryId} {...props} />
);

const ObsCommentEdit = (props) => (
  <CommentEdit observatoryId={observatoryId} {...props} />
);

export default [
  <Resource
    key="users"
    name={"observatories/" + observatoryId + "/users"}
    list={ObsUserList}
    show={UserShow}
    edit={ObsUserEdit}
    icon={People}
    options={{ label: "Utilisateurs" }}
  />,
  <Resource
    key="news"
    name={"observatories/" + observatoryId + "/news"}
    list={ObsNewsList}
    show={ObsNewsShow}
    edit={ObsNewsEdit}
    create={ObsNewsCreate}
    icon={EventNote}
    options={{ label: "Actualités" }}
  />,
  <Resource
    key="participations"
    name={"observatories/" + observatoryId + "/participations"}
    list={ObsParticipationList}
    show={ParticipationShow}
    options={{ label: "Participations" }}
  />,
  <Resource
    key="comments"
    name={"observatories/" + observatoryId + "/comments"}
    list={ObsCommentList}
    edit={ObsCommentEdit}
    icon={ChatBubble}
    options={{ label: "Commentaires" }}
  />,
  <Resource
    key="Structures"
    name={"observatories/" + observatoryId + "/structures"}
    list={StructureList}
    show={StructureShow}
    edit={StructureEdit}
    create={StructureCreate}
    options={{ label: "Structures" }}
    icon={Business}
  />,
];
