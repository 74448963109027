import {
  Datagrid,
  DateField,
  Edit,
  EditButton,
  List,
  ReferenceField,
  SimpleForm,
  TextField,
  RichTextField,
  Filter,
  TextInput,
  DateInput,
  SelectInput,
} from "react-admin";
import React from "react";
import { UserNameField } from "../../users";
import {
  DefaultEditToolbar,
  PublishedInput,
  TruncatedTextField,
} from "../../common";
import {
  PostActions,
  CommentStatusField,
  CommentTitleField,
} from "../../comments";
import { ParticipationNameField } from "./participations";
import PropTypes from "prop-types";

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Pseudo" source="username" alwaysOn />
    <TextInput label="Email" source="email" alwaysOn />
    <DateInput label="Créé du" source="createdFrom" alwaysOn />
    <DateInput label="Créé au" source="createdTo" alwaysOn />
    <SelectInput
      label="Publié"
      source="isDeleted"
      alwaysOn
      choices={[
        { id: 0, name: "Oui" },
        { id: 1, name: "Non" },
      ]}
    />
  </Filter>
);

export const CommentList = ({ observatoryId, ...rest }) => (
  <List
    title="Commentaires"
    sort={{ field: "created", order: "DESC" }}
    bulkActionButtons={false}
    actions={<PostActions />}
    filters={<Filters />}
    {...rest}
  >
    <Datagrid>
      <TextField source="id" />
      <ReferenceField
        sortable={false}
        label="Auteur"
        source="authorId"
        reference={"observatories/" + observatoryId + "/users"}
        link="show"
      >
        <UserNameField />
      </ReferenceField>
      <ReferenceField
        label="Commentaire sur"
        source="participationId"
        reference="BJParticipations"
      >
        <ParticipationNameField />
      </ReferenceField>
      <TruncatedTextField label="Commentaire" source="comment" maxLength={80} />
      <DateField label="Créé le" source="created" showTime />
      <CommentStatusField label="Statut" />
      <EditButton />
    </Datagrid>
  </List>
);

CommentList.propTypes = {
  observatoryId: PropTypes.string,
};

export const CommentEdit = ({ observatoryId, ...rest }) => (
  <Edit {...rest} title={<CommentTitleField />}>
    <SimpleForm toolbar={<DefaultEditToolbar />}>
      <ReferenceField
        sortable={false}
        label="Auteur"
        source="authorId"
        reference={"observatories/" + observatoryId + "/users"}
        link="show"
      >
        <UserNameField />
      </ReferenceField>
      <RichTextField label="Commentaire" source="comment" />
      <ReferenceField
        label="Commentaire sur"
        source="participationId"
        reference="BJParticipations"
      >
        <ParticipationNameField />
      </ReferenceField>
      <DateField label="Créé le" source="created" showTime />
      <PublishedInput label="Commentaire publié" />
    </SimpleForm>
  </Edit>
);

CommentEdit.propTypes = {
  observatoryId: PropTypes.string,
};
