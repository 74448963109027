import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  colors,
} from "@material-ui/core";
import {
  Datagrid,
  DateField,
  Edit,
  EditButton,
  Error,
  ExportButton,
  List,
  Loading,
  ReferenceField,
  SimpleForm,
  TextField,
  useQueryWithStore,
} from "react-admin";
import { DefaultEditToolbar, PublishedInput } from "./common";
import { UserAvatar, UserNameField } from "./users";

import { ParticipationNameField } from "./participations";
import PropTypes from "prop-types";
import React from "react";

const ResourceField = ({ record, observatoryId, ...rest }) => {
  switch (record.resourceType) {
    case "Participation":
      return (
        <ReferenceField
          record={record}
          source="resourceId"
          reference={"observatories/" + observatoryId + "/participations"}
          link="show"
          {...rest}
        >
          <ParticipationNameField />
        </ReferenceField>
      );
    default:
      return (
        <span>
          {record.resourceType} #{record.resourceId}
        </span>
      );
  }
};

ResourceField.defaultProps = {
  addLabel: true,
};

ResourceField.propTypes = {
  observatoryId: PropTypes.string,
  record: PropTypes.shape({
    resourceType: PropTypes.string,
    resourceId: PropTypes.number,
  }),
};

export const CommentTitleField = ({ record }) => {
  return <span>Commentaire #{record.id}</span>;
};

CommentTitleField.defaultProps = {
  addLabel: true,
};

CommentTitleField.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
  }),
};

export const CommentStatusField = ({ record }) => {
  return record.isDeleted ? (
    <span style={{ color: colors.red[500] }}>Hors-ligne</span>
  ) : (
    <span style={{ color: colors.green[500] }}>Publié</span>
  );
};

CommentStatusField.defaultProps = {
  addLabel: true,
};

CommentStatusField.propTypes = {
  record: PropTypes.shape({
    isDeleted: PropTypes.bool,
  }),
};

export const PostActions = () => {
  return <ExportButton maxResults={null} />;
};

export const CommentList = ({ observatoryId, ...rest }) => (
  <List
    title="Commentaires"
    sort={{ field: "created", order: "DESC" }}
    bulkActionButtons={false}
    actions={<PostActions />}
    {...rest}
  >
    <Datagrid>
      <TextField source="id" />
      <ReferenceField
        sortable={false}
        label="Auteur"
        source="userId"
        reference={"observatories/" + observatoryId + "/users"}
        link="show"
      >
        <UserNameField />
      </ReferenceField>
      <ResourceField label="Commentaire sur" observatoryId={observatoryId} />
      <TextField label="Commentaire" source="text" />
      <DateField label="Créé le" source="created" showTime />
      <CommentStatusField label="Statut" />
      <EditButton />
    </Datagrid>
  </List>
);

CommentList.propTypes = {
  observatoryId: PropTypes.string,
};

export const CommentEdit = ({ observatoryId, ...rest }) => (
  <Edit {...rest} title={<CommentTitleField />}>
    <SimpleForm toolbar={<DefaultEditToolbar />}>
      <ReferenceField
        sortable={false}
        label="Auteur"
        source="userId"
        reference={"observatories/" + observatoryId + "/users"}
        link="show"
      >
        <UserNameField />
      </ReferenceField>
      <TextField label="Commentaire" source="text" />
      <ResourceField label="Commentaire sur" observatoryId={observatoryId} />
      <DateField label="Créé le" source="created" showTime />
      <PublishedInput label="Commentaire publié" />
    </SimpleForm>
  </Edit>
);

CommentEdit.propTypes = {
  observatoryId: PropTypes.string,
};

export const ResourceCommentList = ({
  resourceType,
  observatoryId,
  record,
  ...rest
}) => {
  const { loaded, error, data } = useQueryWithStore({
    type: "getList",
    resource: "observatories/" + observatoryId + "/comments",
    payload: {
      filter: {
        resourceType: resourceType,
        resourceId: record.id,
        type: "comment",
      },
      pagination: {
        page: 1,
        perPage: 1000,
      },
      sort: {
        field: "created",
        order: "DESC",
      },
    },
  });
  if (!loaded) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  return (
    <>
      {data.map((record) => (
        <ResourceCommentCard key={record.id} record={record} {...rest} />
      ))}
    </>
  );
};

ResourceCommentList.propTypes = {
  resourceType: PropTypes.string,
  observatoryId: PropTypes.string,
  record: PropTypes.shape({
    id: PropTypes.number,
  }),
};

export const ResourceCommentCard = ({ record, ...rest }) => {
  const username = (
    <ReferenceField
      source="userId"
      resource={"observatories/" + record.observatoryId + "/comments"}
      reference={"observatories/" + record.observatoryId + "/users"}
      record={record}
      link="edit"
      {...rest}
    >
      <UserNameField withAvatar={false} />
    </ReferenceField>
  );

  const avatar = (
    <ReferenceField
      source="userId"
      resource={"observatories/" + record.observatoryId + "/comments"}
      reference={"observatories/" + record.observatoryId + "/users"}
      record={record}
      link="edit"
      {...rest}
    >
      <UserAvatar />
    </ReferenceField>
  );

  const cardStyle = {
    margin: "1em 0",
    display: "block",
  };

  return (
    <Card style={cardStyle}>
      <CardHeader
        title={username}
        avatar={avatar}
        subheader={<DateField record={record} source="created" showTime />}
      />
      <CardContent>
        <TextField record={record} source="text" />
      </CardContent>
      <CardActions style={{ textAlign: "right" }}>
        <EditButton
          basePath={"observatories/" + record.observatoryId + "/comments"}
          resource={"observatories/" + record.observatoryId + "/comments"}
          record={record}
        />
      </CardActions>
    </Card>
  );
};

ResourceCommentCard.propTypes = {
  record: PropTypes.shape({
    observatoryId: PropTypes.string,
  }),
};
