import {
  Create,
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  ExportButton,
  FileInput,
  Filter,
  ImageField,
  ImageInput,
  Labeled,
  List,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  required,
  useQueryWithStore,
} from "react-admin";
import { DefaultEditToolbar, PublishedInput } from "./common";
import { Grid, Typography, colors } from "@material-ui/core";

import PropTypes from "prop-types";
import React from "react";
import RichTextInput from "ra-input-rich-text";
import fetchJson from "react-admin-loopback/lib/fetch";

const Protocols = () => {
  const { data } = useQueryWithStore({
    type: "getMany",
    resource: `observatories/${process.env.REACT_APP_OBS_ID}/protocols`,
  });

  if (data?.length > 0)
    return (
      <SelectInput
        label="Protocoles"
        source="protocolId"
        choices={data}
        fullWidth
      />
    );
  else return null;
};

let newsTypes = [
  { id: "article", name: "Article" },
  { id: "resultats-scientifiques", name: "Résultat scientifique" },
  { id: "newsletter", name: "Newsletter" },
  { id: "temoignage", name: "Témoignage" },
];

switch (process.env.REACT_APP_OBS_ID) {
  case "1":
    newsTypes =
      require(`./observatories/sciencesparticipativesaujardin`).newsTypes;
    break;
  case "2":
    newsTypes = require(`./observatories/jsdijon`).newsTypes;
    break;
  case "3":
    newsTypes = require(`./observatories/spipoll`).newsTypes;
    break;
  case "4":
    newsTypes = require(`./observatories/plagesvivantes`).newsTypes;
    break;
  case "5":
    newsTypes = require(`./observatories/vigieterre`).newsTypes;
    break;
  case "6":
    newsTypes = require(`./observatories/herisson`).newsTypes;
    break;
  default:
    break;
}

const Filters = (props) => {
  return (
    <Filter {...props}>
      <SelectInput
        label="Type de publication"
        source="type"
        alwaysOn
        choices={newsTypes}
      />
    </Filter>
  );
};

const quillOptions = {
  modules: {
    toolbar: {
      container: [
        [{ header: [2, 3, 4, false] }],
        ["bold", "italic", "underline", "strike"],
        ["link", "image"],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
        ["clean"],
      ],
      handlers: {
        image: function () {
          handleImage(this);
        },
      },
    },
  },
};

const handleImage = ({ quill }) => {
  const input = document.createElement("input");
  input.setAttribute("type", "file");
  input.setAttribute('accept', 'image/*')
  input.click();

  input.onchange = () => {
    const file = input.files[0];

    if (/^image\//.test(file.type)) {
      uploadImage(file).then((media) => {
        const range = quill.getSelection();
        quill.insertEmbed(range.index, "image", media.sizes.large);
      });
    }
  };
};

const uploadImage = (file) => {
  const formData = new FormData();
  formData.append("file", file);

  return fetchJson(`${process.env.REACT_APP_API_BASE_URL}medias/upload`, {
    method: "POST",
    body: formData,
  })
    .then((response) => response.json)
    .catch((err) => {
      alert(`Une erreur est survenue : ${err.message}`);
    });
};

const NewsFormContent = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={9}>
        <TextInput
          label="Titre"
          source="title"
          validate={[required()]}
          fullWidth
        />
        <TextInput
          multiline
          label="Résumé de la publication"
          source="summary"
          fullWidth
        />
        <RichTextInput
          label="Contenu"
          source="body"
          options={quillOptions}
          fullWidth
        />
        <TextInput label="Auteur" source="author" fullWidth />
      </Grid>
      <Grid item xs={3}>
        <SelectInput
          label="Type"
          source="type"
          choices={newsTypes}
          validate={[required()]}
          fullWidth
        />
        <Protocols />
        <DateInput
          label="Date de publication"
          source="publicationDate"
          validate={[required()]}
          fullWidth
        />
        <PublishedInput label="Publié" fullWidth />
        <ImageInput source="image" label="Image" accept="image/*" fullWidth>
          <ImageField source="url" title="filename" />
        </ImageInput>
        <FileInput
          source="pdf"
          label="Ajouter un Pdf en piece jointe"
          accept="application/pdf"
        >
          <ImageField source="src" title="title" />
        </FileInput>

        <TextInput label="Crédits de l'image" source="imgCredits" fullWidth />
      </Grid>
    </Grid>
  );
};

const NewsField = ({ label, record, source, children }) => {
  let content;

  if (children) {
    content = children;
  } else if (record && record[source]) {
    content = record[source];
  } else {
    return "";
  }

  return (
    <div>
      <Labeled label={label}>
        <Typography variant="body2">{content}</Typography>
      </Labeled>
    </div>
  );
};

NewsField.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string,
  newsTypes: PropTypes.array,
  record: PropTypes.object,
  children: PropTypes.object,
};

const NewsShowContent = ({ record, newsTypes }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={9}>
        <Typography variant="h3" component="h1">
          {record.title}
        </Typography>
        <Typography
          variant="body2"
          component="div"
          className="news-body"
          dangerouslySetInnerHTML={{ __html: record.body }}
        />
      </Grid>
      <Grid item xs={3}>
        <NewsField label="Type">
          <NewsTypeField record={record} newsTypes={newsTypes} />
        </NewsField>
        <NewsField label="Date de publication">
          <DateField record={record} source="publicationDate" />
        </NewsField>
        <NewsField label="Statut">
          <NewsStatusField record={record} />
        </NewsField>
        {record.image ? (
          <img
            src={record.image.sizes.medium}
            alt=""
            style={{ width: "100%", height: "auto" }}
          />
        ) : (
          ""
        )}
        <NewsField
          source="imgCredit"
          label="Crédits de l'image"
          record={record}
        />
      </Grid>
    </Grid>
  );
};

NewsShowContent.propTypes = {
  newsTypes: PropTypes.array,
  record: PropTypes.object,
};

export const NewsTitleField = (props) => <span>{props.title}</span>;
NewsTitleField.propTypes = {
  title: PropTypes.string,
};

export const NewsStatusField = ({ record }) => {
  return record.isDeleted ? (
    <span style={{ color: colors.red[500] }}>Hors-ligne</span>
  ) : (
    <span style={{ color: colors.green[500] }}>Publié</span>
  );
};

NewsStatusField.propTypes = {
  record: PropTypes.object,
};

NewsStatusField.defaultProps = {
  addLabel: true,
};

export const NewsTypeField = ({ record, newsTypes }) => {
  if (!record.type) {
    return "";
  }

  if (!newsTypes) {
    return record.type;
  }

  const type = newsTypes.find((type) => type.id === record.type);

  return type ? type.name : record.type;
};

NewsTypeField.propTypes = {
  newsTypes: PropTypes.array,
  record: PropTypes.object,
};

NewsTypeField.defaultProps = {
  addLabel: true,
};

export const PostActions = ({ basePath }) => {
  return (
    <>
      <CreateButton basePath={basePath} />
      <ExportButton maxResults={null} />
    </>
  );
};

PostActions.propTypes = {
  basePath: PropTypes.string,
};

export const NewsList = ({ newsTypes, observatoryId, ...rest }) => {
  return (
    <List
      title="Actualités"
      sort={{ field: "created", order: "DESC" }}
      filters={<Filters />}
      bulkActionButtons={false}
      actions={
        <PostActions
          newsTypes={newsTypes}
          observatoryId={observatoryId}
          {...rest}
        />
      }
      {...rest}
    >
      <Datagrid>
        <TextField source="id" />
        <NewsTypeField label="Type" newsTypes={newsTypes} />
        <TextField label="Titre" source="title" />
        <NewsStatusField label="Statut" />
        <DateField label="Date de publication" source="publicationDate" />
        <DateField label="Créé le" source="created" showTime />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

NewsList.propTypes = {
  newsTypes: PropTypes.array,
  observatoryId: PropTypes.string,
};

export const NewsShow = ({ newsTypes, ...rest }) => (
  <Show title={<NewsTitleField />} {...rest}>
    <SimpleShowLayout>
      <NewsShowContent newsTypes={newsTypes} />
    </SimpleShowLayout>
  </Show>
);

NewsShow.propTypes = {
  newsTypes: PropTypes.array,
};

export const NewsEdit = ({ newsTypes, ...rest }) => (
  <Edit title={<NewsTitleField />} {...rest}>
    <SimpleForm toolbar={<DefaultEditToolbar />}>
      <NewsFormContent newsTypes={newsTypes} />
    </SimpleForm>
  </Edit>
);

NewsEdit.propTypes = {
  newsTypes: PropTypes.array,
};

export const NewsCreate = ({ newsTypes, ...rest }) => (
  <Create title="Nouvelle publication" {...rest}>
    <SimpleForm>
      <NewsFormContent newsTypes={newsTypes} />
    </SimpleForm>
  </Create>
);

NewsCreate.propTypes = {
  newsTypes: PropTypes.array,
};
