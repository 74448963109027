import fetchJson from "react-admin-loopback/lib/fetch";

const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () =>
      resolve({
        name: file.filename || file.name,
        data: reader.result,
      });
    reader.onerror = reject;
  });

const dataProvider = (baseUrl, observatoryId, fetch = fetchJson) => {
  const specialParams = ["pagination", "sort", "filter"];

  const headers = new Headers({
    "X-App-Id": observatoryId,
  });

  const buildFilter = (params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const filter = {
      where: {},
    };
    for (let [key, value] of Object.entries(params.filter)) {
      switch (key) {
        case "createdFrom":
          if (!filter.where.and) filter.where.and = [];
          filter.where.and.push({ created: { gte: value } });
          break;
        case "createdTo":
          if (!filter.where.and) filter.where.and = [];
          filter.where.and.push({ created: { lte: value } });
          break;
        case "username":
        case "email":
          filter.where[key] = { like: encodeURIComponent("%" + value + "%") };
          break;
        default:
          filter.where[key] = value;
      }
    }

    if (field) filter.order = [field + " " + order];
    if (perPage >= 0) filter.limit = perPage;
    if (perPage > 0 && page >= 0) filter.skip = (page - 1) * perPage;

    Object.keys(params).forEach((key) => {
      if (!specialParams.includes(key) && params[key] !== undefined) {
        filter[key] = params[key];
      }
    });

    return filter;
  };

  return {
    getList: async (resource, params) => {
      const filter = buildFilter(params);

      if (resource.split("/").pop() === "comments") {
        filter.where.type = "comment";
      }

      if (resource === "SpipollSocialEvents") {
        filter.where.typeId = 4;
      }

      if (resource === "SocialEvents") {
        filter.where.typeId = 4;
      }

      if (process.env.REACT_APP_OBS_TYPE === "R") {
        filter.where.observatoryId = process.env.REACT_APP_OBS_ID;
      }

      if (filter.where.username) {
        let username = filter.where.username.like.replace(/%25/gi, "");
        let response = await fetch(
          `${baseUrl}users/findByUsername?username=${username}%&limit=10`,
          {
            method: "GET",
          }
        );

        let data = response.json;
        data = data.map((d) => d.id);
        filter.where.userId = { inq: data };
      }

      if (filter.where.email) {
        let email = filter.where.email.like.replace(/%25/gi, "");
        let response = await fetch(
          `${baseUrl}users/findByEmail?email=${email}&limit=10`,
          {
            method: "GET",
          }
        );

        let data = response.json;
        data = data.map((d) => d.id);
        filter.where.userId = { inq: data };
      }

      const url = `${baseUrl}${resource}?count=1&filter=${JSON.stringify(
        filter
      )}`;

      return fetch(url, { headers }).then(({ json }) => {
        if (json.data && json.totalCount !== null) {
          return {
            data: json.data,
            total: json.totalCount,
          };
        }

        return {
          data: json,
          total: json.length,
        };
      });
    },
    getOne: (resource, params) => {
      let url = `${baseUrl}${resource}/${params.id}`;
      let filter = {};

      if (resource.split("/").pop() === "participations") {
        url = `${baseUrl}participations/${params.id}`;

        filter = {
          include: [
            "observationArea",
            "participationMedias",
            "observations",
            "parent",
          ],
        };
      }

      if (resource === "BJParticipations") {
        filter = { include: "bjObservations" };
      }

      if (resource === "PJParticipations") {
        filter = { include: "pjObservations" };
      }

      return fetch(`${url}?filter=${JSON.stringify(filter)}`, { headers }).then(
        ({ json }) => {
          return {
            data: json,
          };
        }
      );
    },
    getMany: (resource, params) => {
      let listId = [];

      if (resource === "ThesaurusValues") {
        listId = params.ids.map((id) => ({ uuid: id }));
      }

      if (params?.ids) {
        listId = params.ids.map((id) => ({ id: id }));
      }

      let query = "";

      if (listId.length > 0) {
        const filter = {
          where: { or: listId },
        };
        query = `?count=1&filter=${JSON.stringify(filter)}`;
      }

      const url = `${baseUrl}${resource}${query}`;

      return fetch(url, { headers }).then(({ json }) => {
        const data = json.data ? json.data : json;

        if (resource === "ThesaurusValues") {
          data.forEach((d) => {
            d.id = d.uuid;
          });
        }

        return {
          data: data,
        };
      });
    },
    getManyReference: (resource, params) => {
      const filter = buildFilter(params);
      filter.where[params.target] = params.id;

      const url = `${baseUrl}${resource}?count=1&filter=${JSON.stringify(
        filter
      )}`;

      return fetch(url, { headers }).then(({ json }) => {
        if (json.data && json.totalCount !== null) {
          return {
            data: json.data,
            total: json.totalCount,
          };
        }

        return {
          data: json,
          total: json.length,
        };
      });
    },
    update: async (resource, params) => {
      if ("attachment" in params.data && !params.data.attachment) {
        delete params.data.attachment;
        params.data.attachmentId = null;
      }

      if ("image" in params.data && !params.data.image) {
        delete params.data.image;
        params.data.imageId = null;
      }

      if (params.data.pdf) {
        let data = new FormData();
        let pdfTitle = params.data.pdf.title;

        data.append("file", params.data.pdf.rawFile);
        const options = {
          method: "POST",
          body: data,
        };
        return fetch(`${baseUrl}medias/upload`, options).then(
          async ({ json }) => {
            const url = `${baseUrl}${resource}/${params.id}`;

            for (let [key, value] of Object.entries(params.data)) {
              if (value && value.rawFile) {
                params.data[key] = await convertFileToBase64(value);
              }
            }

            params.data.body =
              params.data.body.replace(
                /<(\w+)\s[^>]*download[^>]*>[\s\S]*\1>/gi,
                ""
              ) +
              '<a download data-title="' +
              pdfTitle +
              '" href="' +
              json.url +
              '">' +
              pdfTitle +
              "</a>";

            const method = resource.indexOf("/") < 0 ? "PATCH" : "PUT";
            const options = {
              method: method,
              body: JSON.stringify(params.data),
            };
            return fetch(url, options).then(({ json }) => ({
              data: json,
            }));
          }
        );
      } else {
        let data = new FormData();

        let options = {
          method: "POST",
          body: data,
        };

        for (let [key, value] of Object.entries(params.data)) {
          if (value && value.rawFile) {
            data.set("file", params.data[key].rawFile);
            const {json} = await fetch(`${baseUrl}medias/upload`, options)
            if(key === "attachment") {
              params.data.attachmentId = json.id
              delete params.data.attachment;
            }
            if(key === "image") {
              params.data.imageId = json.id
              delete params.data.image;
            }
          }
        }

        const url = `${baseUrl}${resource}/${params.id}`;

        const method = resource.indexOf("/") < 0 ? "PATCH" : "PUT";

        options = {
          method: method,
          body: JSON.stringify(params.data),
          headers,
        };

        return fetch(url, options).then(({ json }) => ({
          data: json,
        }));

      }
    },
    updateMany: (resource, params) => {
      return Promise.all(
        params.ids.map((id) =>
          fetch(`${baseUrl}${resource}/${id}`, {
            method: "PUT",
            body: JSON.stringify(params.data),
            headers,
          })
        )
      ).then((responses) => ({
        data: responses.map((response) => response.json),
      }));
    },
    create: async (resource, params) => {
      if ("attachment" in params.data && !params.data.attachment) {
        delete params.data.attachment;
        params.data.attachmentId = null;
      }

      if ("image" in params.data && !params.data.image) {
        delete params.data.image;
        params.data.imageId = null;
      }

      if (params.data.pdf) {
        let data = new FormData();
        let pdfTitle = params.data.pdf.title;
        data.append("file", params.data.pdf.rawFile);
        const options = {
          method: "POST",
          body: data,
        };
        return fetch(`${baseUrl}medias/upload`, options).then(
          async ({ json }) => {
            const url = `${baseUrl}${resource}`;
            for (let [key, value] of Object.entries(params.data)) {
              if (value && value.rawFile) {
                params.data[key] = await convertFileToBase64(value);
              }
            }
            params.data.body =
              params.data.body +
              '<a download  data-title="' +
              pdfTitle +
              '" href="' +
              json.url +
              '" >' +
              pdfTitle +
              "</a>";
            const options = {
              method: "POST",
              body: JSON.stringify(params.data),
            };
            return fetch(url, options).then(({ json }) => ({
              data: { ...params.data, id: json.id },
            }));
          }
        );
      } else {

        let data = new FormData();

        let options = {
          method: "POST",
          body: data,
        };

        for (let [key, value] of Object.entries(params.data)) {
          if (value && value.rawFile) {
            data.set("file", params.data[key].rawFile);
            const {json} = await fetch(`${baseUrl}medias/upload`, options)
            if(key === "attachment") {
              params.data.attachmentId = json.id
              delete params.data.attachment;
            }
            if(key === "image") {
              params.data.imageId = json.id
              delete params.data.image;
            }
          }
        }

        const url = `${baseUrl}${resource}`;

        options = {
          method: "POST",
          body: JSON.stringify(params.data),
          headers,
        };

        return fetch(url, options).then(({ json }) => ({
          data: json,
        }));
      }
    },
    delete: (resource, params) => {
      const url = `${baseUrl}${resource}/${params.id}`;

      const options = {
        method: "DELETE",
        headers,
      };

      return fetch(url, options).then(({ json }) => ({
        data: { ...json, id: params.id },
      }));
    },
    deleteMany: (resource, params) => {
      return Promise.all(
        params.ids.map((id) =>
          fetch(`${baseUrl}${resource}/${id}`, {
            method: "DELETE",
            headers,
          })
        )
      ).then((responses) => ({
        data: responses.map((response) => response.json),
      }));
    },
  };
};

export default dataProvider;
