import {
  BooleanField,
  Datagrid,
  DateField,
  DateInput,
  EmailField,
  Filter,
  ImageField,
  Labeled,
  List,
  ReferenceField,
  SelectInput,
  ShowButton,
  TextField,
  TextInput,
} from "react-admin";

import PropTypes from "prop-types";
import React from "react";
import { UserNameField } from "./users";
import { capitalize } from "lodash";

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Pseudo" source="username" alwaysOn />
    <TextInput label="Email" source="email" alwaysOn />
    <DateInput label="Créé du" source="createdFrom" alwaysOn />
    <DateInput label="Créé au" source="createdTo" alwaysOn />
    <SelectInput
      label="Complète"
      source="complete"
      alwaysOn
      choices={[
        { id: 0, name: "Non" },
        { id: 1, name: "Oui" },
      ]}
    />
  </Filter>
);

export const ParticipationNameField = ({ record = {} }) => {
  return (
    <span>
      {record.model ? capitalize(record.model) : "Participation"} #{record.id}
    </span>
  );
};

ParticipationNameField.defaultProps = {
  addLabel: true,
};
ParticipationNameField.propTypes = {
  record: PropTypes.object,
};

export const ParticipationModelField = ({ record = {} }) => {
  return <span>{capitalize(record.model)}</span>;
};

ParticipationModelField.defaultProps = {
  addLabel: true,
};
ParticipationModelField.propTypes = {
  record: PropTypes.object,
};

export const ParticipationMediaField = ({
  record,
  relation,
  size,
  ...rest
}) => {
  if (!record || !record.participationMedias) {
    return null;
  }

  const media = record.participationMedias.find(
    (media) => media.relation === relation
  );

  return media ? (
    <Labeled {...rest}>
      <a href={media.media.url}>
        <ImageField
          record={media}
          source={"media.sizes." + (size || "medium")}
        />
      </a>
    </Labeled>
  ) : null;
};
ParticipationMediaField.propTypes = {
  record: PropTypes.object,
  relation: PropTypes.string,
  size: PropTypes.string,
};

export const ParticipationList = ({ observatoryId, ...rest }) => {
  return (
    <List
      title="Participations"
      filters={<Filters />}
      sort={{ field: "created", order: "DESC" }}
      bulkActionButtons={false}
      {...rest}
    >
      <Datagrid>
        <TextField source="id" />
        {observatoryId && (
          <ReferenceField
            sortable={false}
            label="Observateur"
            source="userId"
            reference={"observatories/" + observatoryId + "/users"}
            link="show"
          >
            <UserNameField />
          </ReferenceField>
        )}
        {observatoryId && (
          <ReferenceField
            sortable={false}
            label="Email"
            source="userId"
            reference={"observatories/" + observatoryId + "/users"}
            link={false}
          >
            <EmailField label="Email" source="email" />
          </ReferenceField>
        )}
        <ParticipationModelField label="Modèle" />
        <BooleanField label="Complète" source="complete" />
        <DateField label="Créé le" source="created" showTime />
        <DateField label="Modifié le" source="updated" showTime />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

ParticipationList.propTypes = {
  observatoryId: PropTypes.string,
};
