import * as BourdonsParticipations from "../protocols/bourdons/participations";
import * as PapillonsParticipations from "../protocols/papillons/participations";

import { EventNote, LocalFlorist, People } from "@material-ui/icons";
import { NewsCreate, NewsEdit, NewsList, NewsShow } from "../news";
import { UserEdit, UserList, UserShow } from "../users";

import React from "react";
import { Resource } from "react-admin";

const observatoryId = process.env.REACT_APP_DIJON_OBS_ID;

export const newsTypes = [
  { id: "article", name: "Article" },
  { id: "resultats-scientifiques", name: "Résultats scientifiques" },
  { id: "publication-scientifique", name: "Publication scientifique" },
  { id: "evenement", name: "Événement" },
];

const roles = [
  { id: "user", name: "Membre" },
  { id: "animnat", name: "Animateur" },
];

const ObsUserList = (props) => (
  <UserList observatoryId={observatoryId} roles={roles} {...props} />
);

const ObsUserEdit = (props) => (
  <UserEdit observatoryId={observatoryId} roles={roles} {...props} />
);

const ObsNewsList = (props) => (
  <NewsList observatoryId={observatoryId} newsTypes={newsTypes} {...props} />
);

const ObsNewsEdit = (props) => <NewsEdit newsTypes={newsTypes} {...props} />;

const ObsNewsShow = (props) => <NewsShow newsTypes={newsTypes} {...props} />;

const ObsNewsCreate = (props) => (
  <NewsCreate newsTypes={newsTypes} {...props} />
);

export const BourdonsParticipationList = (props) => (
  <BourdonsParticipations.ParticipationList
    observatoryId={observatoryId}
    {...props}
  />
);

export const BourdonsParticipationShow = (props) => (
  <BourdonsParticipations.ParticipationShow
    observatoryId={observatoryId}
    {...props}
  />
);

export const PapillonsParticipationList = (props) => (
  <PapillonsParticipations.ParticipationList
    observatoryId={observatoryId}
    {...props}
  />
);

export const PapillonsParticipationShow = (props) => (
  <PapillonsParticipations.ParticipationShow
    observatoryId={observatoryId}
    {...props}
  />
);

export default [
  <Resource
    key="users"
    name={"observatories/" + observatoryId + "/users"}
    list={ObsUserList}
    show={UserShow}
    edit={ObsUserEdit}
    icon={People}
    options={{ label: "Utilisateurs" }}
  />,
  <Resource
    key="news"
    name={"observatories/" + observatoryId + "/news"}
    list={ObsNewsList}
    show={ObsNewsShow}
    edit={ObsNewsEdit}
    create={ObsNewsCreate}
    icon={EventNote}
    options={{ label: "Actualités" }}
  />,
  <Resource
    key="participations"
    name="BJParticipations"
    list={BourdonsParticipationList}
    show={BourdonsParticipationShow}
    icon={LocalFlorist}
    options={{ label: "Participations Bourdons" }}
  />,
  <Resource
    key="participations"
    name="PJParticipations"
    list={PapillonsParticipationList}
    show={PapillonsParticipationShow}
    icon={LocalFlorist}
    options={{ label: "Participations Papillons" }}
  />,
  <Resource key="observations" name="BJObservations" />,
  <Resource key="thesaurus" name="Thesaurus" />,
];
