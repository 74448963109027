import {
  AccessibilityNewOutlined,
  Business,
  ChatBubble,
  EventNote,
  LocalFlorist,
  LocationOn,
  People,
  SubdirectoryArrowRight,
} from "@material-ui/icons";
import { CommentEdit, CommentList } from "../protocols/plagesvivantes/comments";
import {
  JobCardsCreate,
  JobCardsEdit,
  JobCardsList,
  JobCardsShow,
} from "../protocols/plagesvivantes/job-cards";
import { NewsCreate, NewsEdit, NewsList, NewsShow } from "../news";
import {
  ParticipationDraftList,
  ParticipationDraftShow,
} from "../protocols/plagesvivantes/drafts";
import {
  ParticipationList,
  ParticipationShow,
} from "../protocols/plagesvivantes/participations";
import {
  PressReviewCreate,
  PressReviewEdit,
  PressReviewList,
  PressReviewShow,
} from "../protocols/plagesvivantes/press-review";
import {
  StructureCreate,
  StructureEdit,
  StructureList,
  StructureShow,
} from "../protocols/plagesvivantes/structures";
import {
  TransectList,
  TransectShow,
} from "../protocols/plagesvivantes/transects";
import { UserEdit, UserList, UserShow } from "../users";

import React from "react";
import { Resource } from "react-admin";

export const newsTypes = [
  { id: "article", name: "Article" },
  { id: "publication-scientifique", name: "Publication scientifique" },
  { id: "evenement", name: "Évenement" },
];

const pressReviewTypes = [
  { id: "Presse écrite", name: "Presse écrite" },
  { id: "Presse audio", name: "Presse audio" },
  {
    id: "Presse audio-visuelle",
    name: "Presse audio-visuelle",
  },
];

let jobCardsTypes = [
  { id: "Offre de stage", name: "Offre de stage" },
  { id: "Offre d'emploi", name: "Offre d'emploi" },
  { id: "Offre de bénévolat", name: "Offre de bénévolat" },
];

const observatoryId = process.env.REACT_APP_PLAGESVIVANTES_OBS_ID;
const roles = [
  { id: "user", name: "Membre" },
  { id: "animnat", name: "Animateur national" },
];

const ObsUserList = (props) => (
  <UserList observatoryId={observatoryId} roles={roles} {...props} />
);

const ObsUserEdit = (props) => (
  <UserEdit observatoryId={observatoryId} roles={roles} {...props} />
);

const ObsNewsList = (props) => (
  <NewsList observatoryId={observatoryId} newsTypes={newsTypes} {...props} />
);

const ObsNewsEdit = (props) => <NewsEdit newsTypes={newsTypes} {...props} />;

const ObsNewsShow = (props) => <NewsShow newsTypes={newsTypes} {...props} />;

const ObsNewsCreate = (props) => (
  <NewsCreate newsTypes={newsTypes} {...props} />
);

const ObsPressReviewList = (props) => (
  <PressReviewList pressReviewTypes={pressReviewTypes} {...props} />
);

const ObsPressReviewEdit = (props) => (
  <PressReviewEdit pressReviewTypes={pressReviewTypes} {...props} />
);

const ObsPressReviewShow = (props) => (
  <PressReviewShow pressReviewTypes={pressReviewTypes} {...props} />
);

const ObsPressReviewCreate = (props) => (
  <PressReviewCreate pressReviewTypes={pressReviewTypes} {...props} />
);
const ObsJobCardsList = (props) => (
  <JobCardsList jobCardsTypes={jobCardsTypes} {...props} />
);

const ObsJobCardsEdit = (props) => (
  <JobCardsEdit jobCardsTypes={jobCardsTypes} {...props} />
);

const ObsJobCardsShow = (props) => (
  <JobCardsShow jobCardsTypes={jobCardsTypes} {...props} />
);

const ObsJobCardsCreate = (props) => (
  <JobCardsCreate jobCardsTypes={jobCardsTypes} {...props} />
);

const ObsCommentList = (props) => (
  <CommentList observatoryId={observatoryId} {...props} />
);

const ObsCommentEdit = (props) => (
  <CommentEdit observatoryId={observatoryId} {...props} />
);

export default [
  <Resource
    key="users"
    name={"observatories/" + observatoryId + "/users"}
    list={ObsUserList}
    show={UserShow}
    edit={ObsUserEdit}
    icon={People}
    options={{ label: "Utilisateurs" }}
  />,
  <Resource
    key="news"
    name={"observatories/" + observatoryId + "/news"}
    list={ObsNewsList}
    show={ObsNewsShow}
    edit={ObsNewsEdit}
    create={ObsNewsCreate}
    icon={EventNote}
    options={{ label: "Actualités" }}
  />,
  <Resource
    key="press-review"
    name={"PV_Revue_Presses"}
    list={ObsPressReviewList}
    show={ObsPressReviewShow}
    edit={ObsPressReviewEdit}
    create={ObsPressReviewCreate}
    icon={EventNote}
    options={{ label: "Revues de presse" }}
  />,
  <Resource
    key="job-cards"
    name={"PV_Offres"}
    list={ObsJobCardsList}
    show={ObsJobCardsShow}
    edit={ObsJobCardsEdit}
    create={ObsJobCardsCreate}
    icon={AccessibilityNewOutlined}
    options={{ label: "Fiches de poste" }}
  />,
  <Resource
    key="transects"
    name="PVTransects"
    list={TransectList}
    show={TransectShow}
    options={{ label: "Transects" }}
    icon={LocationOn}
  />,
  <Resource
    key="PVParticipations"
    name="PVParticipations"
    list={ParticipationList}
    show={ParticipationShow}
    options={{ label: "Participations" }}
    icon={LocalFlorist}
  />,
  <Resource
    key="PVParticipationsDrafts"
    name="PVParticipationsDrafts"
    list={ParticipationDraftList}
    show={ParticipationDraftShow}
    options={{ label: "Brouillons" }}
    icon={SubdirectoryArrowRight}
  />,
  <Resource
    key="comments"
    name={"SocialEvents"}
    list={ObsCommentList}
    edit={ObsCommentEdit}
    icon={ChatBubble}
    options={{ label: "Commentaires" }}
  />,
  <Resource
    key="PVStructures"
    name="PVStructures"
    list={StructureList}
    show={StructureShow}
    edit={StructureEdit}
    create={StructureCreate}
    options={{ label: "Structures" }}
    icon={Business}
  />,
  <Resource key="PVObservations" name="PVObservations" />,
  <Resource key="PVObservationsDrafts" name="PVObservationsDrafts" />,
  <Resource key="PVAlguesInconnues" name="PVAlguesInconnues" />,
  <Resource key="PVAlgues" name="PVAlgues" />,
];
