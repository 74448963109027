import {
  CommentStatusField,
  CommentTitleField,
  PostActions,
} from "../../comments";
import {
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  Filter,
  List,
  ReferenceField,
  RichTextField,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import {
  DefaultEditToolbar,
  PublishedInput,
  TruncatedTextField,
} from "../../common";

import { ParticipationNameField } from "./participations";
import PropTypes from "prop-types";
import React from "react";
import { UserNameField } from "../../users";

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Pseudo" source="username" alwaysOn />
    <TextInput label="Email" source="email" alwaysOn />
    <DateInput label="Créé du" source="createdFrom" alwaysOn />
    <DateInput label="Créé au" source="createdTo" alwaysOn />
    <SelectInput
      label="Publié"
      source="isDeleted"
      alwaysOn
      choices={[
        { id: 0, name: "Oui" },
        { id: 1, name: "Non" },
      ]}
    />
  </Filter>
);

export const CommentList = ({ observatoryId, ...rest }) => (
  <List
    title="Commentaires"
    sort={{ field: "created", order: "DESC" }}
    bulkActionButtons={false}
    actions={<PostActions />}
    filters={<Filters />}
    {...rest}
  >
    <Datagrid>
      <TextField source="id" />
      <ReferenceField
        sortable={false}
        label="Auteur"
        source="userId"
        reference={"observatories/" + observatoryId + "/users"}
        link="show"
      >
        <UserNameField />
      </ReferenceField>
      <ReferenceField
        label="Commentaire sur"
        source="resourceId"
        reference={"PVParticipations"}
        link="show"
      >
        <ParticipationNameField />
      </ReferenceField>
      <TruncatedTextField label="Commentaire" source="comment" maxLength={80} />
      <DateField label="Créé le" source="created" showTime />
      <CommentStatusField label="Statut" />
      <EditButton />
    </Datagrid>
  </List>
);

CommentList.propTypes = {
  observatoryId: PropTypes.string,
};

export const CommentEdit = ({ observatoryId, ...rest }) => {
  return (
    <Edit {...rest} title={<CommentTitleField />}>
      <SimpleForm toolbar={<DefaultEditToolbar />}>
        <ReferenceField
          sortable={false}
          label="Auteur"
          source="userId"
          reference={"observatories/" + observatoryId + "/users"}
          link="show"
        >
          <UserNameField />
        </ReferenceField>
        <RichTextField label="Commentaire" source="comment" />
        <ReferenceField
          label="Commentaire sur"
          source="resourceId"
          reference={"PVParticipations"}
          link="show"
        >
          <ParticipationNameField />
        </ReferenceField>
        <DateField label="Créé le" source="created" showTime />
        <PublishedInput label="Commentaire publié" />
      </SimpleForm>
    </Edit>
  );
};

CommentEdit.propTypes = {
  observatoryId: PropTypes.string,
};
