import {
  Datagrid,
  DateField,
  Edit,
  EditButton,
  List,
  ReferenceField,
  SimpleForm,
  TextField,
  RichTextField,
  Filter,
  TextInput,
  DateInput,
  SelectInput,
} from "react-admin";
import React from "react";
import { UserNameField } from "../../users";
import {
  DefaultEditToolbar,
  PublishedInput,
  TruncatedTextField,
} from "../../common";
import {
  PostActions,
  CommentStatusField,
  CommentTitleField,
} from "../../comments";
import { ParticipationNameField } from "./participations";
import PropTypes from "prop-types";

const ResourceField = ({ record, ...rest }) => {
  switch (record.resourceType) {
    case "Spipoll_Participation":
      return (
        <ReferenceField
          record={record}
          source="resourceId"
          reference={"SPIPParticipations"}
          link="show"
          {...rest}
        >
          <ParticipationNameField />
        </ReferenceField>
      );
    default:
      return (
        <span>
          {record.resourceType} #{record.resourceId}
        </span>
      );
  }
};

ResourceField.propTypes = {
  record: PropTypes.object,
};

ResourceField.defaultProps = {
  addLabel: true,
};

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Pseudo" source="username" alwaysOn />
    <TextInput label="Email" source="email" alwaysOn />
    <DateInput label="Créé du" source="createdFrom" alwaysOn />
    <DateInput label="Créé au" source="createdTo" alwaysOn />
    <SelectInput
      label="Publié"
      source="isDeleted"
      alwaysOn
      choices={[
        { id: 0, name: "Oui" },
        { id: 1, name: "Non" },
      ]}
    />
  </Filter>
);

export const CommentList = ({ observatoryId, ...rest }) => (
  <List
    title="Commentaires"
    sort={{ field: "created", order: "DESC" }}
    bulkActionButtons={false}
    actions={<PostActions />}
    filters={<Filters />}
    {...rest}
  >
    <Datagrid>
      <TextField source="id" />
      <ReferenceField
        sortable={false}
        label="Auteur"
        source="userId"
        reference={"observatories/" + observatoryId + "/users"}
        link="show"
      >
        <UserNameField />
      </ReferenceField>
      <ResourceField label="Commentaire sur" />
      <TruncatedTextField label="Commentaire" source="comment" maxLength={80} />
      <DateField label="Créé le" source="created" showTime />
      <CommentStatusField label="Statut" />
      <EditButton />
    </Datagrid>
  </List>
);

CommentList.propTypes = {
  observatoryId: PropTypes.string,
};

export const CommentEdit = ({ observatoryId, ...rest }) => (
  <Edit {...rest} title={<CommentTitleField />}>
    <SimpleForm toolbar={<DefaultEditToolbar />}>
      <ReferenceField
        sortable={false}
        label="Auteur"
        source="userId"
        reference={"observatories/" + observatoryId + "/users"}
        link="show"
      >
        <UserNameField />
      </ReferenceField>
      <RichTextField label="Commentaire" source="comment" />
      <ResourceField label="Commentaire sur" />
      <DateField label="Créé le" source="created" showTime />
      <PublishedInput label="Commentaire publié" />
    </SimpleForm>
  </Edit>
);

CommentEdit.propTypes = {
  observatoryId: PropTypes.string,
};
