import {
  Confirm,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  ExportButton,
  FileField,
  FileInput,
  Filter,
  ImageField,
  ImageInput,
  Labeled,
  List,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  required,
} from "react-admin";
import { DefaultEditToolbar, PublishedInput } from "../../common";
import { Grid, Typography, colors } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { FormDataConsumer } from "react-admin";
import PropTypes from "prop-types";
import RichTextInput from "ra-input-rich-text";
import fetchJson from "react-admin-loopback/lib/fetch";

let choices = [
  { id: "Presse écrite", name: "Presse écrite" },
  { id: "Presse audio", name: "Presse audio" },
  {
    id: "Presse audio-visuelle",
    name: "Presse audio-visuelle",
  },
];

const Filters = (props) => {
  return (
    <Filter {...props}>
      <SelectInput
        label="Type de publication"
        source="type"
        alwaysOn
        choices={choices}
      />
    </Filter>
  );
};

const quillOptions = {
  modules: {
    toolbar: {
      container: [
        [{ header: [2, 3, 4, false] }],
        ["bold", "italic", "underline", "strike"],
        ["link", "image", "iframe"],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
        ["clean"],
      ],
      handlers: {
        image: function () {
          handleImage(this);
        },
        iframe: function () {
          window.dispatchEvent(new Event("openDialog"));
        },
      },
    },
  },
};

const handleImage = ({ quill }) => {
  const input = document.createElement("input");
  input.setAttribute("type", "file");
  input.setAttribute("accept", "image/*");
  input.click();

  input.onchange = () => {
    const file = input.files[0];

    if (/^image\//.test(file.type)) {
      uploadImage(file).then((media) => {
        const range = quill.getSelection();
        quill.insertEmbed(
          range.index,
          "image",
          media.size ? media.sizes.large : media.url
        );
      });
    }
  };
};

const uploadImage = (file) => {
  const headers = new Headers({
    "X-App-Id": 4,
  });
  const formData = new FormData();
  formData.append("file", file);

  return fetchJson(`${process.env.REACT_APP_API_BASE_URL}medias/upload`, {
    method: "POST",
    body: formData,
    headers,
  })
    .then((response) => response.json)
    .catch((err) => {
      alert(`Une erreur est survenue : ${err.message}`);
    });
};

const getAcceptedType = (type) => {
  switch (type) {
    case "Presse écrite":
      return "application/pdf";
    case "Presse audio":
      return "audio/*";
    case "Presse audio-visuelle":
      return "video/*";
    default:
      break;
  }
};

const isValidUrl = (url) => {
  let validUrl =/^https?:\/\/[\w-]+(\.[\w-]+)+[\w.,@?^=%&:/~+#-]*$/
  return validUrl.test(url);
};

const InsertIframeDialog = () => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(false);

  useEffect(() => {
    if (document.querySelector(".Mui-error")) {
      document.querySelector(".Mui-error").remove();
    }
    if (
      !isValidUrl(value) &&
      document.querySelector(".MuiDialogContent-root")
    ) {
      const p = document.createElement("p");
      p.setAttribute(
        "class",
        "MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense"
      );
      p.appendChild(document.createTextNode("Veuillez entrer une url valid"));
      document.querySelector(".MuiDialogContent-root").append(p);
    }
  }, [value]);

  useEffect(() => {
    window.addEventListener("openDialog", () => {
      setOpen(true);
      const form = document.createElement("form");
      form.setAttribute("class", "iframe-form");
      const input = document.createElement("input");

      input.setAttribute("type", "url");
      input.setAttribute(
        "placeholder",
        "https://embed.radiofrance.fr/franceculture/player?id_diffusion=de218a20-4761-447b-9da1-d6f128e211ae"
      );

      form.appendChild(input);

      document.querySelector(".MuiDialogContent-root").append(form);
      input.focus();
      input.addEventListener("change", (e) => {
        setValue(e.target.value);
      });
    });
  }, []);

  return (
    <Confirm
      isOpen={open}
      content={""}
      onConfirm={() => {
        document.querySelector(".MuiDialogContent-root form").submit();
        if (isValidUrl(value)) {
          setOpen(false);
          const p = document.createElement("p");
          const iframe = document.createElement("iframe");
          iframe.setAttribute("src", value);
          iframe.setAttribute("frameBorder", "0");

          p.appendChild(iframe);
          document.querySelector(".ql-editor").append(p);
        } else {
          if (!document.querySelector(".Mui-error")) {
            const p = document.createElement("p");
            p.setAttribute(
              "class",
              "MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense"
            );
            p.appendChild(
              document.createTextNode("Veuillez entrer une url valid")
            );
            document.querySelector(".MuiDialogContent-root").append(p);
          }
        }
      }}
      confirm="ok"
      onClose={() => setOpen(false)}
      title=""
    />
  );
};

const PressReviewFormContent = ({ record }) => {
  return (
    <Grid container spacing={3}>
      <FormDataConsumer>
        {({ formData }) => {
          if (formData.type) {
            return (
              <>
                <Grid item xs={9}>
                  <TextInput
                    label="Titre"
                    source="title"
                    validate={[required()]}
                    fullWidth
                  />

                  <FormDataConsumer>
                    {({ formData }) => {
                      if(formData.type === "Presse écrite") {
                        record.body = null;
                      }
                      if (formData.type !== "Presse écrite") {
                        return (
                          <>
                            <TextInput
                              multiline
                              label="Résumé de la publication"
                              source="summary"
                              fullWidth
                            />
                            <RichTextInput
                              label="Contenu"
                              source="body"
                              options={quillOptions}
                              fullWidth
                            />
                          </>
                        );
                      } else return null;
                    }}
                  </FormDataConsumer>
                  <InsertIframeDialog />
                  <TextInput label="Auteur" source="author" fullWidth />
                </Grid>
                <Grid item xs={3}>
                  <SelectInput
                    label="Type"
                    source="type"
                    choices={choices}
                    validate={[required()]}
                    fullWidth
                  />
                  <DateInput
                    label="Date de publication"
                    source="publicationDate"
                    validate={[required()]}
                    fullWidth
                  />
                  <PublishedInput label="Publié" fullWidth />
                  <FormDataConsumer>
                    {({ formData }) => {
                      if (formData.type !== "Presse écrite") {
                        record.attachment = null;
                      }
                      if (formData.type === "Presse écrite") {
                        return (
                          <div>
                            <>
                              <ImageInput
                                source="image"
                                label="Image"
                                accept="image/*"
                                fullWidth
                                validate={[required()]}
                              >
                                <ImageField source="url" title="filename" />
                              </ImageInput>
                              <FileInput
                                source="attachment"
                                label={`Ajouter une piece jointe`}
                                accept={getAcceptedType(formData.type)}
                                validate={[required()]}
                              >
                                <FileField source="url" title="name" />
                              </FileInput>
                            </>
                          </div>
                        );
                      } else return null;
                    }}
                  </FormDataConsumer>
                </Grid>
              </>
            );
          } else {
            return (
              <Grid item xs={3}>
                <SelectInput
                  label="Type"
                  source="type"
                  choices={choices}
                  validate={[required()]}
                  fullWidth
                />
              </Grid>
            );
          }
        }}
      </FormDataConsumer>
    </Grid>
  );
};

PressReviewFormContent.propTypes = {
  record: PropTypes.object,
};

const PressReviewField = ({ label, record, source, children }) => {
  let content;

  if (children) {
    content = children;
  } else if (record && record[source]) {
    content = record[source];
  } else {
    return "";
  }

  return (
    <div>
      <Labeled label={label}>
        <Typography variant="body2">{content}</Typography>
      </Labeled>
    </div>
  );
};

PressReviewField.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string,
  pressReviewTypes: PropTypes.array,
  record: PropTypes.object,
  children: PropTypes.object,
};

const PressReviewShowContent = ({ record, pressReviewTypes }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={8}>
        <Typography variant="h3" component="h1">
          {record.title}
        </Typography>
        <Typography
          variant="body2"
          component="div"
          className="press-review-body"
          dangerouslySetInnerHTML={{ __html: record.body }}
        />
      </Grid>
      <Grid item xs={4}>
        <PressReviewField label="Type">
          <PressReviewTypeField
            record={record}
            pressReviewTypes={pressReviewTypes}
          />
        </PressReviewField>
        <PressReviewField label="Date de publication">
          <DateField record={record} source="publicationDate" />
        </PressReviewField>
        <PressReviewField label="Statut">
          <PressReviewStatusField record={record} />
        </PressReviewField>
        {record.attachment ? (
          <a href={record.attachment.url} target="_blank" rel="noopener noreferrer">
            {record.attachment.name}
          </a>
        ) : (
          ""
        )}
      </Grid>
    </Grid>
  );
};

PressReviewShowContent.propTypes = {
  pressReviewTypes: PropTypes.array,
  record: PropTypes.object,
};

export const PressReviewTitleField = (props) => <span>{props.title}</span>;

PressReviewTitleField.propTypes = {
  title: PropTypes.string,
};

export const PressReviewStatusField = ({ record }) => {
  return record.isDeleted ? (
    <span style={{ color: colors.red[500] }}>Hors-ligne</span>
  ) : (
    <span style={{ color: colors.green[500] }}>Publié</span>
  );
};

PressReviewStatusField.propTypes = {
  record: PropTypes.object,
};

PressReviewStatusField.defaultProps = {
  addLabel: true,
};

export const PressReviewTypeField = ({ record, pressReviewTypes }) => {
  if (!record.type) {
    return "";
  }

  if (!pressReviewTypes) {
    return record.type;
  }

  const type = pressReviewTypes.find((type) => type.id === record.type);

  return type ? type.name : record.type;
};

PressReviewTypeField.propTypes = {
  pressReviewTypes: PropTypes.array,
  record: PropTypes.object,
};

PressReviewTypeField.defaultProps = {
  addLabel: true,
};

export const PostActions = ({ basePath }) => {
  return (
    <>
      <CreateButton basePath={basePath} />
      <ExportButton maxResults={null} />
    </>
  );
};

PostActions.propTypes = {
  basePath: PropTypes.string,
};

export const PressReviewList = ({ pressReviewTypes, ...rest }) => {
  return (
    <List
      title="Revues de presse"
      sort={{ field: "created", order: "DESC" }}
      filters={<Filters />}
      bulkActionButtons={false}
      actions={<PostActions pressReviewTypes={pressReviewTypes} {...rest} />}
      {...rest}
    >
      <Datagrid>
        <TextField source="id" />
        <PressReviewTypeField
          label="Type"
          pressReviewTypes={pressReviewTypes}
        />
        <TextField label="Titre" source="title" />
        <PressReviewStatusField label="Statut" />
        <DateField label="Date de publication" source="publicationDate" />
        <DateField label="Créé le" source="created" showTime />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

PressReviewList.propTypes = {
  pressReviewTypes: PropTypes.array,
};

export const PressReviewShow = ({ pressReviewTypes, ...rest }) => (
  <Show title={<PressReviewTitleField />} {...rest}>
    <SimpleShowLayout>
      <PressReviewShowContent pressReviewTypes={pressReviewTypes} />
    </SimpleShowLayout>
  </Show>
);

PressReviewShow.propTypes = {
  pressReviewTypes: PropTypes.array,
};

export const PressReviewEdit = ({ pressReviewTypes, ...rest }) => (
  <Edit title={<PressReviewTitleField />} {...rest}>
    <SimpleForm toolbar={<DefaultEditToolbar />}>
      <PressReviewFormContent pressReviewTypes={pressReviewTypes} />
    </SimpleForm>
  </Edit>
);

PressReviewEdit.propTypes = {
  pressReviewTypes: PropTypes.array,
};

export const PressReviewCreate = ({ pressReviewTypes, ...rest }) => (
  <Create title="Nouvelle publication" {...rest}>
    <SimpleForm>
      <PressReviewFormContent pressReviewTypes={pressReviewTypes} />
    </SimpleForm>
  </Create>
);

PressReviewCreate.propTypes = {
  pressReviewTypes: PropTypes.array,
};
