import {
  Datagrid,
  DateField,
  DateInput,
  Filter,
  FunctionField,
  List,
  ReferenceField,
  ReferenceManyField,
  Show,
  ShowButton,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
} from "react-admin";

import PropTypes from "prop-types";
import React from "react";
import { UserNameField } from "../../users";

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Pseudo" source="username" alwaysOn />
    <TextInput label="Email" source="email" alwaysOn />
    <DateInput label="Créé du" source="createdFrom" alwaysOn />
    <DateInput label="Créé au" source="createdTo" alwaysOn />
  </Filter>
);

const ActivitesField = ({ record }) => {
  const activites =
    record.activites && record.activites.length > 0
      ? record.activites
          .map((activite) => `${activite.nom} (${activite.nb} personnes)`)
          .join(", ")
      : "";

  return <span>{activites}</span>;
};

ActivitesField.defaultProps = {
  addLabel: true,
};

ActivitesField.propTypes = {
  record: PropTypes.shape({
    activites: PropTypes.array,
  }),
};

export const ParticipationDraftList = (props) => (
  <List
    title="Liste des participations"
    filters={<Filters />}
    bulkActionButtons={false}
    {...props}
  >
    <Datagrid>
      <TextField source="id" />
      <ReferenceField
        sortable={false}
        label="Observateur"
        source="userId"
        reference={`observatories/${process.env.REACT_APP_OBS_ID}/users`}
        link="show"
      >
        <UserNameField />
      </ReferenceField>
      <ReferenceField
        sortable={false}
        label="Email"
        source="userId"
        reference={`observatories/${process.env.REACT_APP_OBS_ID}/users`}
        link="show"
      >
        <TextField source="email" />
      </ReferenceField>
      <TextField label="Nom de la plage" source="transect.namePlage" />
      <DateField label="Créé le" source="created" showTime />
      <DateField label="Modifié le" source="updated" showTime />
      <ShowButton />
    </Datagrid>
  </List>
);

export const ParticipationDraftShow = (props) => (
  <Show title={`Participation #${props.id}`} {...props}>
    <TabbedShowLayout>
      <Tab label="Général">
        <ReferenceField
          label="Observateur"
          source="userId"
          reference={`v2/observatories/${process.env.REACT_APP_OBS_ID}/users`}
          link="show"
        >
          <UserNameField />
        </ReferenceField>
        <ReferenceField
          label="Transect"
          source="transectId"
          reference="PVTransects"
          link="show"
        >
          <TextField source="id" />
        </ReferenceField>
        <TextField label="Nom de la plage" source="transect.namePlage" />
        <TextField label="Numéro du transect" source="numtransect.value" />
        <DateField label="Date de la sortie" source="dateSortie" />
        <DateField
          label="Heure de début de relevé"
          source="heureDebut"
          showTime
        />
        <FunctionField
          label="Trace de passage de cribleuse"
          render={(record) => (record.passageCribleuse ? "Oui" : "Non")}
        />
        <FunctionField
          label="Accès de la plage aux engins motorisés"
          render={(record) => (record.enginMotorise ? "Oui" : "Non")}
        />
        <ActivitesField label="Pratiques autres observées à l'échelle du transect" />
        <TextField
          label="Longueur totale cumulée de la laisse de mer sur les 25 mètres du transect"
          source="longueurlaisse"
        />
        <TextField
          label="Largeur moyenne de la laisse de mer le long du transect"
          source="largeurlaisse"
        />
        <TextField
          label="Epaisseur moyenne de la laisse de mer le long du transect"
          source="epaisseurlaisse"
        />
        <TextField
          label="Remarques sur l'échantillonnage du transect"
          source="remarque"
        />
        <TextField label="Nombre de quadras" source="nbQuadra.value" />
        <TextField
          label="Validation du quadrat Q1 par un expert"
          source="validationQ1.value"
        />
        <TextField
          label="Validation du quadrat Q2 par un expert"
          source="validationQ2.value"
        />
        <TextField
          label="Validation du quadrat Q3 par un expert"
          source="validationQ3.value"
        />
        <TextField
          label="Validation du quadrat Q4 par un expert"
          source="validationQ4.value"
        />
        <TextField
          label="Validation du quadrat Q5 par un expert"
          source="validationQ5.value"
        />
        <DateField label="Créé le" source="created" showTime />
        <DateField label="Modifié le" source="updated" showTime />
        <DateField
          label="Créé dans SGBD-SP le"
          source="nuxeoCreated"
          showTime
        />
        <DateField
          label="Modifié dans SGBD-SP le"
          source="nuxeoModified"
          showTime
        />
      </Tab>
      <Tab label="Observations">
        <ReferenceManyField
          addLabel={false}
          reference="PVObservationsDrafts"
          target="participationId"
        >
          <Datagrid>
            <TextField label="Taxon" source="taxon.value" />
            <TextField label="Code" source="taxon.code" />
            <TextField label="Abondance Q1" source="abondanceQ1.value" />
            <TextField label="Abondance Q2" source="abondanceQ2.value" />
            <TextField label="Abondance Q3" source="abondanceQ3.value" />
            <TextField label="Abondance Q4" source="abondanceQ4.value" />
            <TextField label="Abondance Q5" source="abondanceQ5.value" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

ParticipationDraftShow.propTypes = {
  id: PropTypes.number,
};
