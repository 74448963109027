import { DefaultEditToolbar, PublishedInput } from "../../common";
import {
  Edit,
  Show,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  useQueryWithStore,
} from "react-admin";
import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";

import { ParticipationNameField } from "../../participations";
import PropTypes from "prop-types";
import { User } from "../../users";

const dateOptions = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};
const formatDate = (date) => {
  return new Date(date).toLocaleDateString("fr-FR", dateOptions);
};

export const ParticipationShow = (props) => {
  return (
    <Show title={<ParticipationNameField />} {...props}>
      <TabbedShowLayout>
        <Tab label="Participation">
          <ParticipationDetail />
        </Tab>
        <Tab label="Observations">
          <ObservationsList />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const ParticipationDetail = ({ record, ...rest }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Id</TableCell>
          <TableCell>Nuit</TableCell>
          <TableCell>Période</TableCell>
          <TableCell>Date</TableCell>
          <TableCell>Complète</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{record.id}</TableCell>
          <TableCell>n° {record.data.nuit}</TableCell>
          <TableCell>
            Du {record.parent ? formatDate(record.parent.data.startDate) : " "}{" "}
            au {record.parent ? formatDate(record.parent.data.endDate) : " "}
          </TableCell>
          <TableCell>
            Le {record.data ? formatDate(record.data.date) : " "}
          </TableCell>
          <TableCell>{record.complete ? "Oui" : "Non"}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Edit {...rest} id={record.id}>
              <SimpleForm toolbar={<DefaultEditToolbar />}>
                <PublishedInput label="Participation publiée" />
              </SimpleForm>
            </Edit>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

ParticipationDetail.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    parent: PropTypes.object,
    data: PropTypes.object,
    complete: PropTypes.bool,
  }),
};

const ObservationsList = ({ record }) => {
  const [observations, setObservations] = useState(record.observations);
  const [order, setOrder] = useState("desc");

  const sort = (order) => {
    setOrder(order);
    const sorted = record.observations.sort((a, b) =>
      order === "desc"
        ? new Date(b.created).getTime() - new Date(a.created).getTime()
        : new Date(a.created).getTime() - new Date(b.created).getTime()
    );
    setObservations(sorted);
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Id</TableCell>
          <TableCell>Observateur</TableCell>
          <TableCell>Modèle</TableCell>
          <TableCell>Taxon</TableCell>
          <TableCell>Empreinte</TableCell>
          <TableCell>
            <TableSortLabel
              active={false}
              direction={order}
              onClick={() => (order === "desc" ? sort("asc") : sort("desc"))}
            >
              Créé le
            </TableSortLabel>
          </TableCell>
          <TableCell>Mis à jour le</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {observations.map((observation) => (
          <TableRow key={observation.id}>
            <TableCell>{observation.id}</TableCell>
            <TableCell>
              <User
                userId={observation.userId}
                obsId={process.env.REACT_APP_HERISSON_OBS_ID}
              />{" "}
            </TableCell>
            <TableCell>{observation.model}</TableCell>
            <ThesaurusValue taxonId={observation.data.taxonId} />

            <TableCell>
              <div
                style={{
                  backgroundImage:
                    "url(" + record.participationMedias[0].media.url + ")",
                  backgroundPosition: observation.data.backgroundPosition,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "1000% 700%",
                  width: "50px",
                  height: "50px",
                }}
                alt=""
              />
            </TableCell>
            <TableCell>{formatDate(observation.created)}</TableCell>
            <TableCell>{formatDate(observation.updated)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

ObservationsList.propTypes = {
  record: PropTypes.shape({
    observations: PropTypes.array,
    participationMedias: PropTypes.array,
  }),
};

const ThesaurusValue = ({ taxonId }) => {
  const { data } = useQueryWithStore({
    type: "getOne",
    resource: `ThesaurusValues`,
    payload: { id: taxonId },
  });

  if (data) return <TableCell>{data.title}</TableCell>;
  else return null;
};

ThesaurusValue.propTypes = {
  taxonId: PropTypes.number,
};
