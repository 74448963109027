import { Admin, Resource } from "react-admin";

import React from "react";
import authProvider from "./authProvider";
import birdlabResources from "./observatories/birdlab";
import dataClient from "./dataProvider";
import dijonResources from "./observatories/jsdijon";
import frenchMessages from "ra-language-french";
import herissonResources from "./observatories/herisson";
import plagesvivantesResources from "./observatories/plagesvivantes";
import polyglotI18nProvider from "ra-i18n-polyglot";
import spipollResources from "./observatories/spipoll";
import spjResources from "./observatories/sciencesparticipativesaujardin";
import vigieterreResources from "./observatories/vigieterre";

const dataProvider = dataClient(
  process.env.REACT_APP_API_BASE_URL,
  process.env.REACT_APP_OBS_ID
);

const apiAuthProvider = authProvider(
  process.env.REACT_APP_API_BASE_URL + "users/login?include=user",
  process.env.REACT_APP_OBS_ID
);

const i18nProvider = polyglotI18nProvider(() => frenchMessages, "fr");

const observatoryResources = () => {
  switch (process.env.REACT_APP_OBS_ID) {
    case process.env.REACT_APP_SPJ_OBS_ID:
      return spjResources;
    case process.env.REACT_APP_DIJON_OBS_ID:
      return dijonResources;
    case process.env.REACT_APP_SPIPOLL_OBS_ID:
      return spipollResources;
    case process.env.REACT_APP_VIGIETERRE_OBS_ID:
      return vigieterreResources;
    case process.env.REACT_APP_HERISSON_OBS_ID:
      return herissonResources;
    case process.env.REACT_APP_BIRDLAB_OBS_ID:
      return birdlabResources;
    case process.env.REACT_APP_PLAGESVIVANTES_OBS_ID:
      return plagesvivantesResources;
    default:
      return [];
  }
};

const App = () => (
  <Admin
    dataProvider={dataProvider}
    authProvider={apiAuthProvider}
    i18nProvider={i18nProvider}
  >
    {observatoryResources()}
    <Resource name="Protocols" />
    <Resource name="Observatories" />
    <Resource name="ThesaurusValues" />
  </Admin>
);

export default App;
