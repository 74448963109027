import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import leaflet from "leaflet";
import retinaMarkerIcon from "leaflet/dist/images/marker-icon-2x.png";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";

import "leaflet/dist/leaflet.css";
import "./index.css";

delete leaflet.Icon.Default.prototype._getIconUrl;

leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: retinaMarkerIcon,
  iconUrl: markerIcon,
  shadowUrl: markerShadow
});

ReactDOM.render(<App />, document.getElementById("root"));
