import {
  BooleanField,
  ChipField,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  Filter,
  List,
  ReferenceField,
  ReferenceManyField,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
} from "react-admin";
import { DefaultEditToolbar, MapField, PublishedInput } from "../../common";

import PropTypes from "prop-types";
import React from "react";
import { UserNameField } from "../../users";
import { colors } from "@material-ui/core";

export const ParticipationNameField = ({ record }) => (
  <span>{record.name ?? ""}</span>
);

ParticipationNameField.propTypes = {
  record: PropTypes.shape({
    name: PropTypes.string,
  }),
};

export const StatusField = ({ record }) => {
  return record.isDeleted ? (
    <span style={{ color: colors.red[500] }}>Hors-ligne</span>
  ) : (
    <span style={{ color: colors.green[500] }}>Publiée</span>
  );
};

StatusField.propTypes = {
  record: PropTypes.shape({
    isDeleted: PropTypes.bool,
  }),
};

StatusField.defaultProps = {
  addLabel: true,
};

export const ImageField = ({ record, source, size = "small" }) =>
  record && record[source] ? (
    <a href={record[source].url} target="_blank" rel="noopener noreferrer">
      <img src={record[source].sizes[size]} alt="" />
    </a>
  ) : null;

ImageField.defaultProps = {
  addLabel: true,
};
ImageField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
  size: PropTypes.string,
};

const TaxonNameField = ({ record }) => {
  if (!record) return null;

  if (!(!record.taxon || record.taxonId === 0)) {
    return <span>{record.taxon.long_name}</span>;
  }

  if (!record.taxonPrecision) {
    return <span>Insecte Inconnu</span>;
  }

  return <span>{record ? `${record.taxonPrecision}` : ""}</span>;
};

TaxonNameField.propTypes = {
  record: PropTypes.shape({
    taxon: PropTypes.string,
    taxonId: PropTypes.number,
    taxonPrecision: PropTypes.string,
  }),
};

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Pseudo" source="username" alwaysOn />
    <TextInput label="Email" source="email" alwaysOn />
    <DateInput label="Créé du" source="createdFrom" alwaysOn />
    <DateInput label="Créé au" source="createdTo" alwaysOn />
    <SelectInput
      label="Publiée"
      source="isDeleted"
      alwaysOn
      choices={[
        { id: 0, name: "Oui" },
        { id: 1, name: "Non" },
      ]}
    />
  </Filter>
);

export const ParticipationList = ({ observatoryId, resource, ...rest }) => (
    <List
      title="Collections"
      sort={{ field: "created", order: "DESC" }}
      filters={<Filters />}
      bulkActionButtons={false}
      {...rest}
    >
      <Datagrid source="id">
        <TextField source="id" />
        <ReferenceField
          sortable={false}
          label="Auteur"
          source="userId"
          reference={"observatories/" + observatoryId + "/users"}
          link="show"
        >
          <UserNameField />
        </ReferenceField>
        <ParticipationNameField label="Nom de la collection" />
        <DateField label="Date" source="heureDebut" showTime />
        <TextField label="Ville" source="city" />
        {resource === "SPIPParticipations" && <StatusField label="Statut" />}
        <ShowButton />

        {resource === "SPIPParticipations" && <EditButton/>}

      </Datagrid>
    </List>
);

ParticipationList.propTypes = {
  observatoryId: PropTypes.string,
};

export const ParticipationShow = ({ observatoryId, ...rest }) => (
  <Show title={<ParticipationNameField />} {...rest}>
    <TabbedShowLayout>
      <Tab label="Général">
        <TextField source="id" />
        <ReferenceField
          sortable={false}
          label="Auteur"
          source="userId"
          reference={"observatories/" + observatoryId + "/users"}
          link="show"
        >
          <UserNameField />
        </ReferenceField>
        <TextField label="Nom de la collection" source="name" />
        <TextField source="commentaire" />
        <DateField label="Créé le" source="created" showTime />
        <DateField label="Modifié le" source="updated" showTime />
      </Tab>
      <Tab label="Plante">
        <TextField source="plante.long_name" label="Nom" />
        <TextField source="plantePrecision" label="Précisions" />
        <ReferenceField
          source="caractereFleurId"
          reference="Thesaurus"
          link={false}
          label="Caractère"
        >
          <TextField source="value" />
        </ReferenceField>
        <ImageField source="imgPlante" label="Plante" />
        <ImageField source="imgFeuille" label="Feuille" />
        <ImageField source="imgFleur" label="Fleur" />
      </Tab>
      <Tab label="Lieu">
        <ImageField source="imgStation" label="Photo" />
        <MapField label="Position" source="geopoint" />
        <ReferenceManyField
          label="Habitat"
          reference="SPIPParticipationHabitats"
          target="participationId"
        >
          <SingleFieldList>
            <ChipField source="habitat.value" link={false} />
          </SingleFieldList>
        </ReferenceManyField>
        <TextField label="Distance d'une ruche (en m)" source="distanceRuche" />
        <ReferenceField
          label="Présence d'une grande culture dans les 50m"
          source="grandeCultureId"
          reference="Thesaurus"
          link={false}
        >
          <TextField source="value" />
        </ReferenceField>
      </Tab>
      <Tab label="Moment">
        <DateField label="Début" source="heureDebut" showTime />
        <DateField label="Fin" source="heureFin" showTime />
        <ReferenceField
          label="Couverture nuageuse"
          source="nebulositeId"
          reference="Thesaurus"
          link={false}
        >
          <TextField source="value" />
        </ReferenceField>
        <ReferenceField
          label="Température approximative"
          source="temperatureId"
          reference="Thesaurus"
          link={false}
        >
          <TextField source="value" />
        </ReferenceField>
        <ReferenceField
          label="Vent"
          source="ventId"
          reference="Thesaurus"
          link={false}
        >
          <TextField source="value" />
        </ReferenceField>
        <BooleanField label="Fleur à l'ombre" source="fleurOmbre" />
      </Tab>
      <Tab label="Insectes">
        <ReferenceManyField
          addLabel={false}
          reference="SPIPObservations"
          target="participationId"
          sort={{ field: "nbTaxonId", order: "DESC" }}
        >
          <Datagrid>
            <ImageField label="Photo" source="imgTaxon1" />
            <TaxonNameField label="Taxon" />
            <TextField
              sortable={false}
              label="Nb taxons"
              source="nbTaxon.value"
            />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

ParticipationShow.propTypes = {
  observatoryId: PropTypes.string,
};

export const ParticipationEdit = ({ ...rest }) => {
  return (
    <Edit title={<ParticipationNameField />} {...rest}>
      <SimpleForm toolbar={<DefaultEditToolbar />}>
        <PublishedInput label="Collection publiée" />
      </SimpleForm>
    </Edit>
  );
};
