import React, { cloneElement } from "react";
import {
    Create,
    Datagrid,
    DateField,
    DateInput,
    DeleteButton,
    Edit,
    EditButton,
    Filter,
    ImageField,
    ImageInput,
    List,
    SaveButton,
    SimpleForm,
    ShowButton,
    Show,
    TabbedShowLayout,
    Tab,
    TextField,
    TextInput,
    Toolbar,
    required,
    email,
    EmailField,
    UrlField,
    SelectArrayInput,
    ArrayField,
    SingleFieldList,
    ChipField
} from "react-admin";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import PropTypes from 'prop-types';

const StringToLabelObject = ({ record, children, ...rest }) =>
    cloneElement(children, {
        record: { label: record },
        ...rest
    });

const StructureName = ({ record }) => {
    return <span>{record ? `${record.name}` : "Nouvelle structure"}</span>;
};

StructureName.propTypes = {
    record: PropTypes.shape({
        name: PropTypes.string,
    })
};

const StructureEditToolbar = props => (
    <Toolbar {...props}>
        <SaveButton />
        <DeleteButton undoable={false} />
    </Toolbar>
);

const StructuresFilters = props => (
    <Filter {...props}>
        <TextInput label="Nom" source="name" alwaysOn />
        <TextInput label="site web" source="webSite" alwaysOn />
        <TextInput label="Email" source="email" alwaysOn />
        <DateInput label="Créé du" source="createdFrom" alwaysOn />
        <DateInput label="Créé au" source="createdTo" alwaysOn />
    </Filter>
);

const validateName = required();
const validateEmail = [required(), email()];

const StructureImage = ({ record }) => {
    return record.image ? (
        <img alt={record.name} src={process.env.REACT_APP_API_BASE_URL + `/${record.image.url}`} width="300" />
    ) : (
        ""
    );
};

StructureImage.propTypes = {
    record: PropTypes.shape({
        name: PropTypes.string,
        image: PropTypes.shape({
            url: PropTypes.string,
        })
    }),
};

const StructureMap = ({ record }) => {
    return record.geopoint ? (
        <MapContainer center={record.geopoint} zoom={12}>
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
            />
            <Marker position={record.geopoint} />
        </MapContainer>
    ) : (
        ""
    );
};

StructureMap.propTypes = {
    record: PropTypes.shape({
        geopoint: PropTypes.array,
    })
};

export const StructureList = props => (
    <List title="Liste des structures" filters={<StructuresFilters />} bulkActionButtons={false} {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField label="Nom de la structure" source="name" />
            <UrlField label="Site web de la structure" source="webSite" />
            <ArrayField source="protocols">
                <SingleFieldList>
                    <StringToLabelObject>
                        <TextField source="label" />
                    </StringToLabelObject>
                </SingleFieldList>
            </ArrayField>
            <EmailField label="Email" source="email" />
            <TextField label="Ville" source="address.city" />
            <DateField label="Créé le" source="created" showTime />
            <DateField label="Modifié le" source="updated" showTime />
            <EditButton />
            <ShowButton />
        </Datagrid>
    </List>
);

export const StructureShow = props => (
    <Show title={<StructureName />} {...props}>
        <TabbedShowLayout>
            <Tab label="Général">
                <TextField source="id"/>
                <StructureImage />
                <TextField label="Nom" source="name"/>
                <UrlField label="Site web de la structure" source="webSite" />
                <ArrayField source="protocols">
                    <SingleFieldList>
                        <StringToLabelObject>
                            <ChipField source="label" />
                        </StringToLabelObject>
                    </SingleFieldList>
                </ArrayField>

                <EmailField label="Email" source="email" />
                <TextField label="Description" source="description"/>
                <TextField label="Numéro de téléphone" source="phone"/>
                <TextField label="Adresse" source="address.name" />
                <TextField label="Code postal" source="address.postcode"/>
                <TextField label="Ville" source="address.city" />
                <DateField label="Créé le" source="created" showTime/>
                <DateField label="Modifié le" source="updated" showTime/>
                <StructureMap />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export const StructureCreate = props => (
    <Create title="Nouvelle structure" {...props}>
        <SimpleForm redirect="show">
            <TextField source="id" fullWidth/>
            <TextInput label="Nom" source="name" validate={validateName} fullWidth/>
            <TextInput label="Site web" source="webSite" fullWidth/>
            <SelectArrayInput fullWidth
                label="Protocoles"
                source="protocols"
                choices={[
                    { id: "alamer", name: "Alamer" },
                    { id: "olamer", name: "Olamer" },
                    { id: "floramer", name: "Floramer" }
                ]}
            />
            <TextInput label="Email" source="email" validate={validateEmail} fullWidth/>
            <TextInput multiline label="Description" source="description" fullWidth/>
            <TextInput label="Numéro de téléphone" source="phone" fullWidth/>
            <TextInput multiline  label="Adresse" source="addressValue" fullWidth/>
            <ImageInput label="Image ou logo" source="image">
                <ImageField source="src" title="title" />
            </ImageInput>
        </SimpleForm>
    </Create>
);

export const StructureEdit = props => (
    <Edit title={<StructureName />} {...props}>
        <SimpleForm toolbar={<StructureEditToolbar />} redirect="show">
            <TextField source="id" fullWidth/>
            <TextInput label="Nom" source="name" validate={validateName} fullWidth/>
            <TextInput label="Site web" source="webSite" fullWidth/>
            <SelectArrayInput fullWidth
                label="Protocoles"
                source="protocols"
                choices={[
                    { id: "alamer", name: "Alamer" },
                    { id: "olamer", name: "Olamer" },
                    { id: "floramer", name: "Floramer" }
                ]}
            />
            <TextInput label="Email" source="email" validate={validateEmail} fullWidth/>
            <TextInput multiline  label="Description" source="description" fullWidth/>
            <TextInput label="Numéro de téléphone" source="phone" fullWidth/>
            <TextInput multiline  label="Nouvelle adresse" source="addressValue" fullWidth />
            <ImageInput label="Image ou logo" source="image">
                <ImageField source="src" title="title" />
            </ImageInput>
        </SimpleForm>
    </Edit>
);
