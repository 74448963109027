import React from "react";
import {
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  Filter,
  ImageField,
  ImageInput,
  List,
  SimpleForm,
  ShowButton,
  Show,
  SimpleShowLayout,
  TextField,
  TextInput,
  required,
  email,
  minLength,
  maxLength,
} from "react-admin";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { DefaultEditToolbar } from "./common";

const StructureName = ({ record }) => {
  return <span>{record ? `${record.name}` : "Nouvelle structure"}</span>;
};

const StructureAddressLabel = ({ record }) => {
  if (record.geodata) {
    if (record.geodata.label) {
      return <span>{record.geodata.label}</span>;
    }
    if (record.geodata.address.label) {
      return <span>{record.geodata.address.label}</span>;
    }
  } else return "";
};

StructureAddressLabel.defaultProps = {
  addLabel: true,
};

const StructuresFilters = (props) => (
  <Filter {...props}>
    <TextInput label="Nom" source="name" alwaysOn />
    <TextInput label="Email" source="email" alwaysOn />
    <DateInput label="Créé du" source="createdFrom" alwaysOn />
    <DateInput label="Créé au" source="createdTo" alwaysOn />
  </Filter>
);

const validateName = required();
const validateEmail = [required(), email()];

const StructureMap = ({ record }) => {
  return record.geopoint ? (
    <MapContainer center={record.geopoint} zoom={12}>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
      />
      <Marker position={record.geopoint} />
    </MapContainer>
  ) : (
    ""
  );
};

export const StructureList = (props) => (
  <List
    title="Structures"
    filters={<StructuresFilters />}
    sort={{ field: "created", order: "DESC" }}
    bulkActionButtons={false}
    {...props}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField label="Nom de la structure" source="name" />
      <TextField label="Email" source="email" />
      <StructureAddressLabel label="Adresse" source="geodata" />
      <DateField label="Créé le" source="created" showTime />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);

export const StructureShow = (props) => {
  return (
    <Show title={<StructureName />} {...props}>
      <SimpleShowLayout>
        <TextField label="Nom" source="name" />
        <TextField label="Email" source="email" />
        <TextField label="Description" source="description" multiline />
        <ImageField label="Image ou logo" source="image.sizes.small" />
        <TextField label="Numéro de téléphone" source="phone" />
        <StructureAddressLabel label="Adresse" source="geodata" />
        <DateField label="Créé le" source="created" showTime />
        <StructureMap />
      </SimpleShowLayout>
    </Show>
  );
};

export const StructureCreate = (props) => {
  return (
    <Create title="Nouvelle structure" {...props}>
      <SimpleForm redirect="show">
        <TextInput
          label="Nom"
          source="name"
          validate={validateName}
          fullWidth
        />
        <TextInput
          label="Email"
          source="email"
          validate={validateEmail}
          fullWidth
        />
        <TextInput
          label="Description"
          source="description"
          multiline
          fullWidth
          validate={descriptionMaxLength}
        />
        <TextInput label="Numéro de téléphone" source="phone" />
        <TextInput label="Adresse" source="addressValue" fullWidth />
        <ImageInput label="Image ou logo" source="image">
          <ImageField source="src" title="filename" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

const descriptionMaxLength = [minLength(0), maxLength(750)];

export const StructureEdit = (props) => (
  <Edit title={<StructureName />} {...props}>
    <SimpleForm toolbar={<DefaultEditToolbar />} redirect="show">
      <TextInput label="Nom" source="name" validate={validateName} fullWidth />
      <TextInput
        label="Email"
        source="email"
        validate={validateEmail}
        fullWidth
      />
      <TextInput
        label="Description"
        source="description"
        multiline
        fullWidth
        validate={descriptionMaxLength}
      />
      <TextInput label="Numéro de téléphone" source="phone" />
      <StructureAddressLabel label="Adresse" />
      <TextInput label="Nouvelle adresse" source="addressValue" fullWidth />
      <ImageInput label="Image ou logo" source="image">
        <ImageField source="sizes.small" title="filename" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);
