import {
  Datagrid,
  DateField,
  DateInput,
  Filter,
  List,
  ReferenceField,
  Show,
  ShowButton,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
} from "react-admin";
import { MapContainer, Polyline, TileLayer } from "react-leaflet";

import PropTypes from "prop-types";
import React from "react";
import { UserNameField } from "../../users";

const TransectName = ({ record }) => {
  return <span>Transect {record ? `${record.namePlage}` : ""}</span>;
};

TransectName.propTypes = {
  record: PropTypes.shape({
    namePlage: PropTypes.string,
  }),
};

export const TransectMap = ({ record }) => {
  const whenReady = (event) => {
    event.target.fitBounds(record.geopolyline);
  };
  return (
    <MapContainer
      center={[46.3622, 1.5231]}
      zoom={6}
      whenReady={whenReady.bind(this)}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
      />
      <Polyline positions={record.geopolyline} />
    </MapContainer>
  );
};

TransectMap.propTypes = {
  record: PropTypes.shape({
    geopolyline: PropTypes.array,
  }),
};

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Pseudo" source="username" alwaysOn />
    <TextInput label="Email" source="email" alwaysOn />
    <DateInput label="Créé du" source="createdFrom" alwaysOn />
    <DateInput label="Créé au" source="createdTo" alwaysOn />
  </Filter>
);

export const TransectList = (props) => (
  <List
    title="Liste des transects"
    filters={<Filters />}
    bulkActionButtons={false}
    {...props}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField label="Nom de la plage" source="namePlage" />
      <ReferenceField
        sortable={false}
        label="Observateurs"
        source="userId"
        reference={`observatories/${process.env.REACT_APP_OBS_ID}/users`}
        link="show"
      >
        <UserNameField />
      </ReferenceField>
      <ReferenceField
        sortable={false}
        label="Email"
        source="userId"
        reference={`observatories/${process.env.REACT_APP_OBS_ID}/users`}
        link="show"
      >
        <TextField source="email" />
      </ReferenceField>
      <DateField label="Créé le" source="created" showTime />
      <DateField label="Modifié le" source="updated" showTime />
      <ShowButton />
    </Datagrid>
  </List>
);

export const TransectShow = (props) => (
  <Show title={<TransectName />} {...props}>
    <TabbedShowLayout>
      <Tab label="Général">
        <TextField source="id" />
        <TextField label="Nom" source="namePlage" />
        <DateField label="Créé le" source="created" showTime />
        <DateField label="Modifié le" source="updated" showTime />
        <TransectMap />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
