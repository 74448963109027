import storage from "react-admin-loopback/lib/storage";

const authProvider = (loginUrl, observatoryId, noAccessPage = "/login") => {
  return {
    login: ({ username, password }) => {
      // Test si username est un email
      const body = /\S+@\S+\.\S+/.test(username) ? {
        email: username,
        password: password,
      } : {
        username: username,
        password: password,
      };

      const request = new Request(loginUrl, {
        method: "POST",
        body: JSON.stringify(body),
        headers: new Headers({
          "Content-Type": "application/json",
          "X-App-Id": observatoryId,
        }),
      });

      return fetch(request)
        .then((response) => {
          if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .then(({ ttl, ...data }) => {
          storage.save("lbtoken", data, ttl);
        });
    },
    logout: () => {
      storage.remove("lbtoken");
      return Promise.resolve();
    },
    checkError: (params) => {
      const { status } = params;
      if (status === 401 || status === 403) {
        storage.remove("lbtoken");
        return Promise.reject();
      }
      return Promise.resolve();
    },
    checkAuth: () => {
      const token = storage.load("lbtoken");

      if (token && token.id) {
        return Promise.resolve();
      } else {
        storage.remove("lbtoken");
        return Promise.reject({ redirectTo: noAccessPage });
      }
    },
    getPermissions: () => {
      const token = storage.load("lbtoken");
      return token
        ? Promise.resolve(token.user ? token.user.roles : [])
        : Promise.reject();
    },
  };
};

export default authProvider;
