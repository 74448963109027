import { Avatar, Grid } from "@material-ui/core";
import {
  BooleanField,
  BooleanInput,
  CheckboxGroupInput,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  EmailField,
  ExportButton,
  Filter,
  List,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  useQueryWithStore,
} from "react-admin";

import { DefaultEditToolbar } from "./common";
import PropTypes from "prop-types";
import React from "react";

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Pseudo" source="username" alwaysOn />
    <TextInput label="Email" source="email" alwaysOn />
    <TextInput label="Code postal" source="postalCode" alwaysOn />
    <DateInput label="Créé du" source="createdFrom" alwaysOn />
    <DateInput label="Créé au" source="createdTo" alwaysOn />
    <SelectInput
      label="Compte vérifié"
      source="emailVerified"
      alwaysOn
      choices={[
        { id: 0, name: "Non" },
        { id: 1, name: "Oui" },
      ]}
    />
  </Filter>
);

export const User = ({ userId, obsId }) => {
  const { data } = useQueryWithStore({
    type: "getOne",
    resource: `observatories/${obsId}/users`,
    payload: { id: userId },
  });
  if (data) return <UserNameField record={data} />;
  else return null;
};

User.propTypes = {
  userId: PropTypes.number,
  obsId: PropTypes.number,
};

export const UserNameField = ({ record, withAvatar = true }) => {
  if (!withAvatar) {
    return <span>{record.username}</span>;
  }

  return (
    <Grid container direction="row" alignItems="center" spacing={1}>
      <Grid item>
        <Avatar
          src={record.avatar ? record.avatar.sizes.thumb : ""}
          alt={record.username}
          width={50}
          height={50}
        />
      </Grid>
      <Grid item>
        <span>{record.username}</span>
      </Grid>
    </Grid>
  );
};

UserNameField.propTypes = {
  record: PropTypes.object,
  withAvatar: PropTypes.bool,
};

export const UserAvatar = ({ record }) => (
  <Avatar
    src={record.avatar ? record.avatar.sizes.thumb : ""}
    alt={record.username}
    width={50}
    height={50}
  />
);

UserAvatar.propTypes = {
  record: PropTypes.object,
};

const UserObservatoryRoles = ({ record, choices }) => {
  if (!record.roles) {
    return "";
  }

  const roles = record.roles.map((name) => {
    for (let choice of choices) {
      if (choice.id === name) {
        return choice.name;
      }
    }

    return name;
  });

  return <span>{roles.sort().join(", ")}</span>;
};

UserObservatoryRoles.propTypes = {
  record: PropTypes.object,
  choices: PropTypes.array,
};

export const PostActions = () => {
  return <ExportButton maxResults={null} />;
};

export const UserList = ({ observatoryId, roles, ...rest }) => (
  <List
    title="Utilisateurs"
    filters={<Filters />}
    sort={{ field: "created", order: "DESC" }}
    actions={<PostActions />}
    bulkActionButtons={false}
    {...rest}
  >
    <Datagrid>
      <TextField label="id" source="id" />
      <UserNameField />
      <EmailField label="Email" source="email" />
      <BooleanField label="Compte verifié" source="emailVerified" />
      {observatoryId && roles && (
        <UserObservatoryRoles label="Rôles" choices={roles} />
      )}
      <TextField label="Code postal" source="postalCode" />
      <DateField label="Créé le" source="created" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);

UserList.propTypes = {
  observatoryId: PropTypes.string,
  roles: PropTypes.array,
};

export const UserEdit = ({ observatoryId, roles, ...rest }) => (
  <Edit title={<UserNameField />} {...rest}>
    <SimpleForm toolbar={<DefaultEditToolbar />}>
      <TextInput label="Pseudo" source="username" fullWidth />
      <TextInput label="Email" source="email" fullWidth />
      <TextInput label="Code postal" source="postalCode" fullWidth />
      <BooleanInput label="Compte vérifié" source="emailVerified" fullWidth />
      {observatoryId && roles && (
        <CheckboxGroupInput source="roles" choices={roles} />
      )}
    </SimpleForm>
  </Edit>
);

UserEdit.propTypes = {
  observatoryId: PropTypes.string,
  roles: PropTypes.array,
};

export const UserShow = (props) => (
  <Show {...props} title={<UserNameField />}>
    <SimpleShowLayout>
      <TextField label="Email" source="email" />
      <TextField label="Code postal" source="postalCode" />
      <DateField
        label="Année de naissance"
        source="birthDate"
        options={{ year: "numeric" }}
      />
      <DateField label="Créé le" source="created" showTime />
      <DateField label="Modifié le" source="updated" showTime />
    </SimpleShowLayout>
  </Show>
);
