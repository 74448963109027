import {
  Create,
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  ExportButton,
  FileField,
  FileInput,
  Filter,
  Labeled,
  List,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  required
} from "react-admin";
import { DefaultEditToolbar, PublishedInput } from "../../common";
import { Grid, Typography, colors } from "@material-ui/core";

import PropTypes from "prop-types";
import React from "react";
import RichTextInput  from "ra-input-rich-text";
import fetchJson from "react-admin-loopback/lib/fetch";

let choices = [
  { id: "Offre de stage", name: "Offre de stage" },
  { id: "Offre d'emploi", name: "Offre d'emploi" },
  { id: "Offre de bénévolat", name: "Offre de bénévolat" }
]


const Filters = (props) => {
  return (
    <Filter {...props}>
      <SelectInput
        label="Type de publication"
        source="type"
        alwaysOn
        choices={choices}
      />
    </Filter>
  );
};

const quillOptions = {
  modules: {
    toolbar: {
      container: [
        [{ header: [2, 3, 4, false] }],
        ["bold", "italic", "underline", "strike"],
        ["link", "image"],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
        ["clean"],
      ],
      handlers: {
        image: function () {
          handleImage(this);
        },
      },
    },
  },
};

const handleImage = ({ quill }) => {
  const input = document.createElement("input");
  input.setAttribute("type", "file");
  input.setAttribute('accept', 'image/*')
  input.click();

  input.onchange = () => {
    const file = input.files[0];

    if (/^image\//.test(file.type)) {
      uploadImage(file).then((media) => {
        const range = quill.getSelection();
        quill.insertEmbed(range.index, "image", media.sizes.large);
      });
    }
  };
};

const uploadImage = (file) => {
  const formData = new FormData();
  formData.append("file", file);

  return fetchJson(`${process.env.REACT_APP_API_BASE_URL}medias/upload`, {
    method: "POST",
    body: formData,
  })
    .then((response) => response.json)
    .catch((err) => {
      alert(`Une erreur est survenue : ${err.message}`);
    });
};

const JobCardsFormContent = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={9}>
        <TextInput
          label="Titre"
          source="title"
          validate={[required()]}
          fullWidth
        />
        <TextInput
          multiline
          label="Résumé de la publication"
          source="summary"
          fullWidth
        />
        <RichTextInput
          label="Contenu"
          source="body"
          options={quillOptions}
          fullWidth
        />
      </Grid>
      <Grid item xs={3}>
        {
          <SelectInput
            label="Type"
            source="type"
            choices={choices}
            validate={[required()]}
            fullWidth
          />
        }
        <DateInput
          label="Date de publication"
          source="publicationDate"
          validate={[required()]}
          fullWidth
        />
        <PublishedInput label="Publié" fullWidth />
        <FileInput
          source="attachment"
          label="Ajouter un Pdf en piece jointe"
          accept="application/pdf"
        >
          <FileField source="url" title="name" />
        </FileInput>

      </Grid>
    </Grid>
  );
};

const JobCardsField = ({ label, record, source, children }) => {
  let content;

  if (children) {
    content = children;
  } else if (record && record[source]) {
    content = record[source];
  } else {
    return "";
  }

  return (
    <div>
      <Labeled label={label}>
        <Typography variant="body2">{content}</Typography>
      </Labeled>
    </div>
  );
};

JobCardsField.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string,
  jobCardsTypes: PropTypes.array,
  record: PropTypes.object,
  children: PropTypes.object,
};

const JobCardsShowContent = ({ record, jobCardsTypes }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={8}>
        <Typography variant="h3" component="h1">
          {record.title}
        </Typography>
        <Typography
          variant="body2"
          component="div"
          className="press-review-body"
          dangerouslySetInnerHTML={{ __html: record.body }}
        />
      </Grid>
      <Grid item xs={4}>
        <JobCardsField label="Type">
          <JobCardsTypeField record={record} jobCardsTypes={jobCardsTypes} />
        </JobCardsField>
        <JobCardsField label="Date de publication">
          <DateField record={record} source="publicationDate" />
        </JobCardsField>
        <JobCardsField label="Statut">
          <JobCardsStatusField record={record} />
        </JobCardsField>
        {record.attachment ? (
          <a href={record.attachment.url} target="_blank" rel="noopener noreferrer">{record.attachment.name}</a>
        ) : (
          ""
        )}

      </Grid>
    </Grid>
  );
};

JobCardsShowContent.propTypes = {
  jobCardsTypes: PropTypes.array,
  record: PropTypes.object,
};

export const JobCardsTitleField = (props) => <span>{props.title}</span>;

JobCardsTitleField.propTypes = {
  title: PropTypes.string,
};

export const JobCardsStatusField = ({ record }) => {
  return record.isDeleted ? (
    <span style={{ color: colors.red[500] }}>Hors-ligne</span>
  ) : (
    <span style={{ color: colors.green[500] }}>Publié</span>
  );
};

JobCardsStatusField.propTypes = {
  record: PropTypes.object,
};

JobCardsStatusField.defaultProps = {
  addLabel: true,
};

export const JobCardsTypeField = ({ record, jobCardsTypes }) => {
  if (!record.type) {
    return "";
  }

  if (!jobCardsTypes) {
    return record.type;
  }

  const type = jobCardsTypes.find((type) => type.id === record.type);

  return type ? type.name : record.type;
};

JobCardsTypeField.propTypes = {
  jobCardsTypes: PropTypes.array,
  record: PropTypes.object,
};

JobCardsTypeField.defaultProps = {
  addLabel: true,
};

export const PostActions = ({ basePath }) => {
  return (
    <>
      <CreateButton basePath={basePath} />
      <ExportButton maxResults={null} />
    </>
  );
};

PostActions.propTypes = {
  basePath: PropTypes.string,
};

export const JobCardsList = ({ jobCardsTypes, ...rest }) => {
  return (
    <List
      title="Fiches de poste"
      sort={{ field: "created", order: "DESC" }}
      filters={<Filters />}
      bulkActionButtons={false}
      actions={<PostActions jobCardsTypes={jobCardsTypes} {...rest} />}
      {...rest}
    >
      <Datagrid>
        <TextField source="id" />
        <JobCardsTypeField label="Type" jobCardsTypes={jobCardsTypes} />
        <TextField label="Titre" source="title" />
        <JobCardsStatusField label="Statut" />
        <DateField label="Date de publication" source="publicationDate" />
        <DateField label="Créé le" source="created" showTime />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

JobCardsList.propTypes = {
  jobCardsTypes: PropTypes.array,
};

export const JobCardsShow = ({ jobCardsTypes, ...rest }) => (
  <Show title={<JobCardsTitleField />} {...rest}>
    <SimpleShowLayout>
      <JobCardsShowContent jobCardsTypes={jobCardsTypes} />
    </SimpleShowLayout>
  </Show>
);

JobCardsShow.propTypes = {
  jobCardsTypes: PropTypes.array,
};

export const JobCardsEdit = ({ jobCardsTypes, ...rest }) => (
  <Edit title={<JobCardsTitleField />} {...rest}>
    <SimpleForm toolbar={<DefaultEditToolbar />}>
      <JobCardsFormContent jobCardsTypes={jobCardsTypes} />
    </SimpleForm>
  </Edit>
);

JobCardsEdit.propTypes = {
  jobCardsTypes: PropTypes.array,
};

export const JobCardsCreate = ({ jobCardsTypes, ...rest }) => (
  <Create title="Nouvelle publication" {...rest}>
    <SimpleForm>
      <JobCardsFormContent jobCardsTypes={jobCardsTypes} />
    </SimpleForm>
  </Create>
);

JobCardsCreate.propTypes = {
  jobCardsTypes: PropTypes.array,
};
