import {
  ChatBubble,
  EventNote,
  LocalFlorist,
  People,
} from "@material-ui/icons";
import { CommentEdit, CommentList } from "../protocols/spipoll/comments";
import { NewsCreate, NewsEdit, NewsList, NewsShow } from "../news";
import {
  ParticipationEdit,
  ParticipationList,
  ParticipationShow
} from "../protocols/spipoll/participations";
import { UserEdit, UserList, UserShow } from "../users";

import GoArrowRight from "@material-ui/icons/SubdirectoryArrowRight";
import React from "react";
import { Resource } from "react-admin";

const observatoryId = process.env.REACT_APP_SPIPOLL_OBS_ID;

const roles = [
  { id: "user", name: "Membre" },
  { id: "expert", name: "Expert" },
  { id: "animnat", name: "Animateur national" },
];

export const newsTypes = [
  { id: "article", name: "Article" },
  { id: "resultats-scientifiques", name: "Résultats scientifiques" },
  { id: "publication-scientifique", name: "Publication scientifique" },
  { id: "evenement", name: "Événement" },
];

const ObsUserList = (props) => (
  <UserList observatoryId={observatoryId} roles={roles} {...props} />
);

const ObsUserEdit = (props) => (
  <UserEdit observatoryId={observatoryId} roles={roles} {...props} />
);

const ObsNewsList = (props) => (
  <NewsList observatoryId={observatoryId} newsTypes={newsTypes} {...props} />
);

const ObsNewsEdit = (props) => <NewsEdit newsTypes={newsTypes} {...props} />;

const ObsNewsShow = (props) => <NewsShow newsTypes={newsTypes} {...props} />;

const ObsNewsCreate = (props) => (
  <NewsCreate newsTypes={newsTypes} {...props} />
);

const ObsParticipationList = (props) => (
  <ParticipationList observatoryId={observatoryId} {...props} />
);

const ObsParticipationShow = (props) => (
  <ParticipationShow observatoryId={observatoryId} {...props} />
);

const ObsParticipationEdit = (props) => (
  <ParticipationEdit observatoryId={observatoryId} {...props} />
)

const ObsCommentList = (props) => (
  <CommentList observatoryId={observatoryId} {...props} />
);

const ObsCommentEdit = (props) => (
  <CommentEdit observatoryId={observatoryId} {...props} />
);

export default [
  <Resource
    key="users"
    name={"observatories/" + observatoryId + "/users"}
    list={ObsUserList}
    show={UserShow}
    edit={ObsUserEdit}
    icon={People}
    options={{ label: "Utilisateurs" }}
  />,
  <Resource
    key="news"
    name={"observatories/" + observatoryId + "/news"}
    list={ObsNewsList}
    show={ObsNewsShow}
    edit={ObsNewsEdit}
    create={ObsNewsCreate}
    icon={EventNote}
    options={{ label: "Actualités" }}
  />,
  <Resource
    key="collections"
    name="SPIPParticipations"
    list={ObsParticipationList}
    show={ObsParticipationShow}
    edit={ObsParticipationEdit}
    icon={LocalFlorist}
    options={{ label: "Collections" }}
  />,
  <Resource
    key="brouillons"
    name="spipparticipationdrafts"
    list={ObsParticipationList}
    show={ObsParticipationShow}
    options={{ label: "Brouillons" }}
    icon={GoArrowRight}
  />,
  <Resource
    key="comments"
    name="SpipollSocialEvents"
    list={ObsCommentList}
    edit={ObsCommentEdit}
    icon={ChatBubble}
    options={{ label: "Commentaires" }}
  />,
  <Resource key="habitats" name="SPIPParticipationHabitats" />,
  <Resource key="observations" name="SPIPObservations" />,
  <Resource key="thesaurus" name="Thesaurus" />,
  <Resource
    key="spipparticipationdrafthabitats"
    name="spipparticipationdrafthabitats"
  />,
  <Resource key="spipobservationdrafts" name="spipobservationdrafts" />,
];
