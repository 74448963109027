import { BooleanInput, SaveButton, Toolbar, useInput } from "react-admin";
import React, { useCallback } from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import PropTypes from "prop-types";
import { get } from "lodash";

export const DefaultEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const PublishedInput = ({ source = "isDeleted", ...rest }) => {
  const { input } = useInput({
    type: "checkbox",
    source: source,
    ...rest,
  });

  const handleChange = useCallback(
    (event, value) => {
      input.onChange(!value);
    },
    [input]
  );

  const options = {
    onChange: handleChange,
    checked: !input.checked,
  };

  return <BooleanInput {...rest} source={source} options={options} />;
};

export const TruncatedTextField = ({ record, source, maxLength = 100 }) => {
  let content = record[source];

  const el = document.createElement("div");
  el.innerHTML = content;

  content =
    el.textContent.length < maxLength
      ? el.textContent
      : el.textContent.slice(0, maxLength - 1) + "…";

  return content;
};

TruncatedTextField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
};

export const MapField = ({ record, source }) => {
  const position = get(record, source);

  const whenReady = (event) => {
    event.target.setView(position, 12);
  };

  return (
    <MapContainer
      center={[46.3622, 1.5231]}
      zoom={6}
      whenReady={whenReady.bind(this)}
      style={{ width: "600px" }}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
      />
      <Marker position={position} />
    </MapContainer>
  );
};

MapField.defaultProps = {
  addLabel: true,
};
