import {
  ArrayField,
  ChipField,
  ImageField,
  ReferenceField,
  SingleFieldList,
  Tab,
  TextField,
} from "react-admin";

import { MapField } from "./common";
import React from "react";

export const ContexteTab = (props) => (
  <Tab label="Contexte" {...props}>
    <TextField label="Année" source="lastObservationAreaYear.year" />
    <ArrayField
      label="Composition"
      source="lastObservationAreaYear.compositionOA"
    >
      <SingleFieldList link={false}>
        <ChipField source="compositionOA.value" />
      </SingleFieldList>
    </ArrayField>
    <TextField label="Type" source="lastObservationAreaYear.typeOA.value" />
    <TextField
      label="Environnement"
      source="lastObservationAreaYear.environmentOA.value"
    />
    <TextField
      label="Surface"
      source="lastObservationAreaYear.surfaceOA.value"
    />
    <TextField
      label="Distance Bois"
      source="lastObservationAreaYear.distanceBois.value"
    />
    <TextField
      label="Distance Champ"
      source="lastObservationAreaYear.distanceChamp.value"
    />
    <TextField
      label="Distance Prairie"
      source="lastObservationAreaYear.distancePrairie.value"
    />
    <ReferenceField
      reference="Thesaurus"
      source="lastObservationAreaYear.typeEngraisId"
      label="Engrais"
      link={false}
    >
      <TextField source="value" />
    </ReferenceField>
    <ReferenceField
      reference="Thesaurus"
      source="lastObservationAreaYear.frequenceEngraisId"
      label="Fréquence Engrais"
      link={false}
    >
      <TextField source="value" />
    </ReferenceField>
    <ReferenceField
      reference="Thesaurus"
      source="lastObservationAreaYear.typeInsecticideId"
      label="Insecticide"
      link={false}
    >
      <TextField source="value" />
    </ReferenceField>
    <ReferenceField
      reference="Thesaurus"
      source="lastObservationAreaYear.frequenceInsecticideId"
      label="Fréquence Insecticide"
      link={false}
    >
      <TextField source="value" />
    </ReferenceField>
    <ReferenceField
      reference="Thesaurus"
      source="lastObservationAreaYear.typeHerbicideId"
      label="Herbicide"
      link={false}
    >
      <TextField source="value" />
    </ReferenceField>
    <ReferenceField
      reference="Thesaurus"
      source="lastObservationAreaYear.frequenceHerbicideId"
      label="Fréquence Herbicide"
      link={false}
    >
      <TextField source="value" />
    </ReferenceField>
    <ReferenceField
      reference="Thesaurus"
      source="lastObservationAreaYear.typeFongicideId"
      label="Fongicide"
      link={false}
    >
      <TextField source="value" />
    </ReferenceField>
    <ReferenceField
      reference="Thesaurus"
      source="lastObservationAreaYear.frequenceFongicideId"
      label="Fréquence Fongicide"
      link={false}
    >
      <TextField source="value" />
    </ReferenceField>
    <ReferenceField
      reference="Thesaurus"
      source="lastObservationAreaYear.typeAntiLimaceId"
      label="Anti_Limace"
      link={false}
    >
      <TextField source="value" />
    </ReferenceField>
    <ReferenceField
      reference="Thesaurus"
      source="lastObservationAreaYear.frequenceAntiLimaceId"
      label="Fréquence Anti_Limace"
      link={false}
    >
      <TextField source="value" />
    </ReferenceField>
    <ReferenceField
      reference="Thesaurus"
      source="lastObservationAreaYear.typeBouillieBordelaiseId"
      label="Bouillie Bordelaise"
      link={false}
    >
      <TextField source="value" />
    </ReferenceField>
    <ReferenceField
      reference="Thesaurus"
      source="lastObservationAreaYear.frequenceBouillieBordelaiseId"
      label="Fréquence Bouillie Bordelaise"
      link={false}
    >
      <TextField source="value" />
    </ReferenceField>
  </Tab>
);

export const JardinTab = (props) => (
  <Tab label="Jardin" {...props}>
    <TextField
      label="Nom"
      source="lastObservationAreaYear.observationArea.name"
    />
    <TextField
      label="Adresse"
      source="lastObservationAreaYear.observationArea.address"
    />
    <TextField
      label="Code postal"
      source="lastObservationAreaYear.observationArea.postalCode"
    />
    <TextField
      label="Ville"
      source="lastObservationAreaYear.observationArea.city"
    />
    <ImageField
      label="Image"
      source="lastObservationAreaYear.observationArea.image.sizes.medium"
    />
    <MapField
      label="Coordonnées"
      source="lastObservationAreaYear.observationArea.geopoint"
    />
  </Tab>
);
