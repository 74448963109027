import * as BourdonsComments from "../protocols/bourdons/comments";
import * as BourdonsParticipations from "../protocols/bourdons/participations";
import * as PapillonsComments from "../protocols/papillons/comments";
import * as PapillonsParticipations from "../protocols/papillons/participations";

import {
  ChatBubble,
  EventNote,
  LocalFlorist,
  People,
} from "@material-ui/icons";
import { NewsCreate, NewsEdit, NewsList, NewsShow } from "../news";
import { UserEdit, UserList, UserShow } from "../users";

import React from "react";
import { Resource } from "react-admin";

const observatoryId = process.env.REACT_APP_SPJ_OBS_ID;

const roles = [
  { id: "user", name: "Membre" },
  { id: "animnat", name: "Animateur national" },
];

export const newsTypes = [
  { id: "article", name: "Article" },
  { id: "resultat-scientifique", name: "Résultat scientifique" },
  { id: "publication-scientifique", name: "Publication scientifique" },
];

const ObsUserList = (props) => (
  <UserList observatoryId={observatoryId} roles={roles} {...props} />
);

const ObsUserEdit = (props) => (
  <UserEdit observatoryId={observatoryId} roles={roles} {...props} />
);

const ObsNewsList = (props) => (
  <NewsList observatoryId={observatoryId} newsTypes={newsTypes} {...props} />
);

const ObsNewsEdit = (props) => <NewsEdit newsTypes={newsTypes} {...props} />;

const ObsNewsShow = (props) => <NewsShow newsTypes={newsTypes} {...props} />;

const ObsNewsCreate = (props) => (
  <NewsCreate newsTypes={newsTypes} {...props} />
);

export const BourdonsParticipationList = (props) => (
  <BourdonsParticipations.ParticipationList
    observatoryId={observatoryId}
    {...props}
  />
);

export const BourdonsParticipationShow = (props) => (
  <BourdonsParticipations.ParticipationShow
    observatoryId={observatoryId}
    {...props}
  />
);

export const BourdonsCommentList = (props) => (
  <BourdonsComments.CommentList observatoryId={observatoryId} {...props} />
);

export const BourdonsCommentEdit = (props) => (
  <BourdonsComments.CommentEdit observatoryId={observatoryId} {...props} />
);

export const PapillonsParticipationList = (props) => (
  <PapillonsParticipations.ParticipationList
    observatoryId={observatoryId}
    {...props}
  />
);

export const PapillonsParticipationShow = (props) => (
  <PapillonsParticipations.ParticipationShow
    observatoryId={observatoryId}
    {...props}
  />
);

export const PapillonsCommentList = (props) => (
  <PapillonsComments.CommentList observatoryId={observatoryId} {...props} />
);

export const PapillonsCommentEdit = (props) => (
  <PapillonsComments.CommentEdit observatoryId={observatoryId} {...props} />
);

export default [
  <Resource
    key="users"
    name={"observatories/" + observatoryId + "/users"}
    list={ObsUserList}
    show={UserShow}
    edit={ObsUserEdit}
    icon={People}
    options={{ label: "Utilisateurs" }}
  />,
  <Resource
    key="news"
    name={"observatories/" + observatoryId + "/news"}
    list={ObsNewsList}
    show={ObsNewsShow}
    edit={ObsNewsEdit}
    create={ObsNewsCreate}
    icon={EventNote}
    options={{ label: "Actualités" }}
  />,
  <Resource
    key="participations"
    name="BJParticipations"
    list={BourdonsParticipationList}
    show={BourdonsParticipationShow}
    icon={LocalFlorist}
    options={{ label: "Participations Bourdons" }}
  />,
  <Resource
    key="comments"
    name="BJParticipationComments"
    list={BourdonsCommentList}
    edit={BourdonsCommentEdit}
    icon={ChatBubble}
    options={{ label: "Commentaires Bourdons" }}
  />,
  <Resource
    key="participations"
    name="PJParticipations"
    list={PapillonsParticipationList}
    show={PapillonsParticipationShow}
    icon={LocalFlorist}
    options={{ label: "Participations Papillons" }}
  />,
  <Resource
    key="comments"
    name="PJParticipationComments"
    list={PapillonsCommentList}
    edit={PapillonsCommentEdit}
    icon={ChatBubble}
    options={{ label: "Commentaires Papillons" }}
  />,
  <Resource key="observations" name="BJObservations" />,
  <Resource key="thesaurus" name="Thesaurus" />,
];
