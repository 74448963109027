import React from "react";
import {
  ArrayField,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  List,
  ReferenceField,
  Show,
  ShowButton,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
} from "react-admin";
import { UserNameField } from "../../users";
import { get } from "lodash";
import { ContexteTab, JardinTab } from "../../jardin";
import PropTypes from "prop-types";

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Pseudo" source="username" alwaysOn />
    <TextInput label="Email" source="email" alwaysOn />
    <DateInput label="Créé du" source="createdFrom" alwaysOn />
    <DateInput label="Créé au" source="createdTo" alwaysOn />
  </Filter>
);

export const ParticipationNameField = ({ record }) => {
  const year = new Date(record.startDate).getFullYear();
  return (
    <span>
      {record.observationAreaName} {year} Semaine {record.week.value}
    </span>
  );
};

ParticipationNameField.propTypes = {
  record: PropTypes.object,
};

ParticipationNameField.defaultProps = {
  addLabel: true,
};

export const YearField = ({ record, source }) => {
  const year = new Date(get(record, source)).getFullYear();
  return <span>{year}</span>;
};

YearField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
};

YearField.defaultProps = {
  addLabel: true,
};

export const ParticipationList = ({ observatoryId, ...rest }) => (
  <List
    title="Participations Papillon"
    sort={{ field: "created", order: "DESC" }}
    filters={<Filters />}
    bulkActionButtons={false}
    {...rest}
  >
    <Datagrid source="id">
      <TextField source="id" />
      <ReferenceField
        sortable={false}
        label="Auteur"
        source="userId"
        reference={"observatories/" + observatoryId + "/users"}
        link="show"
      >
        <UserNameField />
      </ReferenceField>
      <TextField sortable={false} label="Jardin" source="observationAreaName" />
      <YearField sortable={false} label="Année" source="startDate" />
      <TextField sortable={false} label="Semaine" source="week.value" />
      <ReferenceField
        label="Observatoire"
        source="observatoryId"
        reference="Observatories"
      >
        <TextField source="name" />
      </ReferenceField>
      <DateField label="Crée le" source="created" showTime={true} />
      <ShowButton />
    </Datagrid>
  </List>
);

ParticipationList.propTypes = {
  observatoryId: PropTypes.string,
};

export const ParticipationShow = ({ observatoryId, ...rest }) => (
  <Show title={<ParticipationNameField />} {...rest}>
    <TabbedShowLayout>
      <Tab label="Général">
        <TextField source="id" />
        <ReferenceField
          label="Auteur"
          source="userId"
          reference={"observatories/" + observatoryId + "/users"}
          link="show"
        >
          <UserNameField />
        </ReferenceField>
        <TextField label="Commentaire" source="comment" />
        <DateField label="Créé le" source="created" showTime />
        <DateField label="Modifié le" source="updated" showTime />
      </Tab>
      <Tab label="Observations">
        <ArrayField source="pjObservations" addLabel={false}>
          <Datagrid>
            <TextField label="Taxon" source="taxon.value" />
            <TextField label="Nombre" source="nbTaxons" />
          </Datagrid>
        </ArrayField>
      </Tab>
      <ContexteTab />
      <JardinTab />
    </TabbedShowLayout>
  </Show>
);

ParticipationShow.propTypes = {
  observatoryId: PropTypes.string,
};
